@mixin button-with-arrow($backgroundColour, $fontColour, $hoverBackgroundColour: null) {
	background: transparent;
	border: none;
	cursor: pointer;
	text-align: left;
	text-decoration: none;
	background: $backgroundColour;
	padding: 9px 10px 9px 20px;
	@include clearfix();

	> span {
		color: $fontColour;
		font-size: 18px;
		font-weight: bold;
		line-height: 36px;
		padding-right: 25px;
		vertical-align: middle;
		display: inline-block;
		width: auto;
		min-width: 150px;
	}

	> i {
		background: url($imagesDir + 'iconArrowRightWhite.png') center center no-repeat;
		background-size: 28%;
		padding: 18px 10px;
		vertical-align: middle;
		width: 34px;
		display: inline-block;
	}

	@if $hoverBackgroundColour {
		@include transition(background 0.3s);

		&:hover {
			background-color: $hoverBackgroundColour;
		}
	}
}

@mixin headingFeature() {
	color: $colourDefaultFont;
	font-family: $fontTelstraDisplay;
	font-size: $h1DefaultSize;
	font-weight: 400;
	line-height: $fontTelstraDisplayLineHeightH1;
	margin: 0 0 32px 0;
	text-align: left;
}

@mixin horiztonalCenter {
	@include position(absolute, null null null 50%);
	@include transform(translateX(-50%));
}

@mixin verticalCenter {
	@include position(absolute, 50% null null 50%);
	@include transform(translateX(-50%) translateY(-50%));
}

@mixin themedNeedHelp($themeColour, $hoverColour) {
	a {
		color: $themeColour;

		&:hover {
			color: $hoverColour;
		}
	}
}

@mixin themedNotificationBox($themeColour) {
	&.focusWithin,
	&:hover {
		background-color: $themeColour;

		.notificationsBox_list {
			right: 0;
			left: auto;
		}

		.notificationsBox_button {
			position: relative;
			background-color: $themeColour;
		}

		.notificationsBox_icon {
			color: $colourWhite;
			border-color: $colourWhite;
			position: absolute;
			left: 4px;
		}
	}
}

@mixin themedNotification($themeColour, $hoverColour) {
	$fontColour: $colourGreyL1;
	$width: 300px;
	$maxHeight: 522px;
	$spacing: 16px;

	line-height: 9px;
	display: inline-block;

	&_button {
		position: relative;
		display: inline-block;
		color: $fontColour;
		@include size(33px 40px);
	}

	&_seeAll {
		display: block;
		padding: 10px;
		text-align: center;

		& a {
			color: $themeColour;
			text-decoration: none;

			&:hover {
				color: $hoverColour;
				text-decoration: underline;
			}
		}
	}

	&_count {
		@extend .animated;
		@extend .zoomIn;
		position: absolute;
		top: -3px;
		left: -6px;
		background-color: $themeColour;
		border-radius: 100%;
		color: #FFF;
		font-size: 11px;
		padding-top: 2px;
		padding-right: 1px;
		text-align: center;
		overflow: hidden;
		@include size(22px);
		@include animation-delay(1s);
		@include animation-duration(0.3s);
	}

	&_icon {
		display: inline-block;
		text-align: center;
		padding-top: 4px;
		margin-right: 4px;
		margin-top: 8px;
		position: absolute;
		left: 3px;
		@include size(25px);
	}

	&_list {
		width: $width;
		position: absolute;
		left: -99999em;
		text-align: left;
		border: 1px solid $themeColour;
		background-color: #FFF;
		line-height: 115%;

		&_header {
			display: block;
			background-color: $themeColour;
			padding: 7px;
			color: #FFF;
			font-size: 16px;
		}

		&_itemContainer {
			max-height: $maxHeight;
			overflow-y: auto;
		}

		&_item {
			$contentSpacing: 16px;

			border-bottom: 1px solid $colourGreyL3;
			margin: {
				left: $spacing;
				right: $spacing;
			}
			padding: {
				top: $spacing;
			}
			width: $width - ($spacing * 2);

			&_header {
				margin-bottom: $contentSpacing;
				display: inline-block;
			}

			&:last-child {
				border-bottom: none;
			}

			p {
				white-space: pre-wrap;
				margin: {
					top: 0;
					bottom: $contentSpacing;
				}
			}

			a {
				color: $themeColour;
				display: inline-block;
				margin-bottom: 16px;
				text-decoration: none;

				&:hover {
					color: $hoverColour;
					text-decoration: underline;
				}
			}
		}
	}
}

@mixin themedWhatsNewPage($themeColour, $colourPagination, $colourHover) {
	$contentSpacing: 16px;

	&-popup {
		overflow: auto;
		padding: 8px 0;
	}

	&-pageHome {
		border: 1px solid $colourGreyL3;
		float: right;
		width: 25%;
		min-height: 372px;
		overflow-y: auto;
		margin-bottom: 16px;
	}

	h1 {
		color: $themeColour;
		font-family: $fontTelstraDisplay;
		font-size: $h1DefaultSize;
		font-weight: 400;
		line-height: $fontTelstraDisplayLineHeightH1;
		margin: 0 0 40px 0;
	}

	h2 {
		color: $themeColour;
		font-family: $fontTelstraDisplay;
		font-size: $h2DefaultSize;
		font-weight: 400;
		line-height: $fontTelstraDisplayLineHeightH2;
		margin: 0 0 32px 0;
	}

	&_action {
		text-align: center;
	}

	.pagination,
	&_list {
		padding-bottom: 48px;

		&-pageHome {
			padding-bottom: 0;
			margin: {
				top: 10px;
				right: 10px;
				left: 10px;
			}

			h3 {
				color: $themeColour;
				font-size: 18px;
				margin: 0;
			}

		}
	}

	.pagination {
		li {
			> a {
				color: $colourPagination;
			}

			&.active,
			&:hover {
				> a {
					background: $colourPagination;
					color: #fff;
				}
			}
		}
	}

	&_item {
		margin-bottom: $contentSpacing * 2;
		p {
			white-space: pre-wrap;
			margin: {
				top: 0;
				bottom: $contentSpacing;
			}
		}

		a {
			color: $colourPagination;
			text-decoration: none;

			&:hover {
				color: $colourHover;
				text-decoration: underline;
			}
		}

		&-asList {
			border-bottom: 1px solid $colourGreyL3;
			padding: {
				top: 5px;
				bottom: 25px;
				left: 5px;
				right: 5px;
			}
			margin-bottom: 25px;

			&:last-child {
				border-bottom: none;
			}
		}

		&-pageHome {
			padding: 0;
			margin: 0;
			border-bottom: 1px solid $colourGreyL3;
			p, a {
				white-space: pre-line;
				font-size: 14px;
				margin-top: 10px;
				margin-bottom: 10px;
			}
		}

		&_header {
			margin-bottom: $contentSpacing;
			display: inline-block;
		}

	}

	&_button {
		border: none;
		margin: 0;
		cursor: pointer;
		padding: 12px 14px;
		font-size: 16px;
		background-color: $colourPagination;
		color: $colourWhite;

		&:hover {
			background-color: $colourHover;
		}
	}
}

@mixin gradientUnderline() {

	&::after {
		bottom: -3px;
		content: "";
		height: 3px;
		left: 0;
		position: absolute;
		width: 100%;
	}

	&.blue::after {
		@include linear-gradient(90deg, #5BC0F2 0%, #C69DC9 35%, #8779B7 70%);
	}

	&.green::after {
		background-color: $colourGreenL2;
	}

	&.magenta::after {
		@include linear-gradient(90deg, #C24279 0%, #EA5153 35%, #F7AB59 70%);
	}

	&.orange::after {
		@include linear-gradient(90deg, #F7AB59 0%, #EA5153 35%, #C24279 70%);
	}

	&.purple::after {
		@include linear-gradient(90deg, #8779B7 0%, #C69DC9 35%, #5BC0f2 70%);
	}

	&.turquoise::after {
		@include linear-gradient(90deg, #009792 0%, #23B8D6 35%, #C6D86b 70%);
	}

	&.sky::after {
		background-color: $colourSky60;
	}
	&.sky::after {
		background-color: $colourEarth60;
	}
}

@mixin tdWithWidth($w) {
	.td {
		width: #{w}
	}
}

@mixin small-button($backgroundColour, $fontColour, $hoverBackgroundColour: null) {
	background: transparent;
	border: none;
	cursor: pointer;
	text-align: center;
	text-decoration: none;
	padding: 0;
	box-sizing: border-box;
	@include clearfix();

	> span {
		background: $backgroundColour;
		color: $fontColour;
		font-size: 14px;
		font-weight: bold;
		line-height: 40px;
		padding: 0 20px 0 20px;
		vertical-align: middle;
		display: inline-block;
		box-sizing: border-box;
	}

	> i {
		background: $backgroundColour url($imagesDir + 'iconArrowRightWhite.png') center center no-repeat;
		background-size: 28%;
		padding: 18px 10px;
		vertical-align: middle;
		width: 34px;
		display: inline-block;
	}

	@if $hoverBackgroundColour {
		> i,
		> span {
			@include transition(background 0.3s);
		}

		&:hover {
			> i,
			> span {
				background-color: $hoverBackgroundColour;
			}
		}
	}
}

@mixin button-with-arrow-left($backgroundColour, $fontColour, $hoverBackgroundColour: null) {
	background: transparent;
	border: none;
	cursor: pointer;
	text-align: right;
	text-decoration: none;
	@include clearfix();

	> span {
		background: $backgroundColour;
		color: $fontColour;
		font-size: 14px;
		font-weight: bold;
		line-height: 36px;
		padding: 0 10px 0 25px;
		vertical-align: middle;
		display: inline-block;
	}

	> i {
		background: $backgroundColour url($imagesDir + 'iconArrowLeftWhite.png') center center no-repeat;
		background-size: 28%;
		padding: 18px 10px;
		vertical-align: middle;
		width: 34px;
		display: inline-block;
	}

	@if $hoverBackgroundColour {
		> i,
		> span {
			@include transition(background 0.3s);
		}

		&:hover {
			> i,
			> span {
				background-color: $hoverBackgroundColour;
			}
		}
	}
}