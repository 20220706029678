@include font-face("Gravur", "#{$fontDir}gravur/TelstraGravurWeb-Regular");
@include font-face("Gravur", "#{$fontDir}gravur/TelstraGravurWeb-Bold", bold);
@include font-face("Gravur", "#{$fontDir}gravur/TelstraGravurWeb-Light", 100);
$fontGravur: "Gravur", Helvetica, Arial, sans-serif;
$fontGravurLineHeight: 90%;

@include font-face("Akkurat", "#{$fontDir}Akkurat/TelstraAkkuratWeb-Regular");
@include font-face("Akkurat", "#{$fontDir}Akkurat/TelstraAkkuratWeb-Bold", bold);
@include font-face("Akkurat", "#{$fontDir}Akkurat/TelstraAkkuratWeb-Italic", normal, italic);
@include font-face("Akkurat", "#{$fontDir}Akkurat/TelstraAkkuratWeb-BoldItalic", bold, italic);
@include font-face("Akkurat", "#{$fontDir}Akkurat/TelstraAkkuratWeb-Light", 100);
$fontAkkurat: "Akkurat", Helvetica, Arial, sans-serif;

$fontAkkuratLineHeight: 25px;
$fontAkkuratLineHeightH1: 104%;
$fontAkkuratLineHeightH2: 112.5%;
$fontAkkuratLineHeightH3: 125%;
$fontAkkuratLineHeightH4: 122%;
$fontAkkuratLineHeightH5: 125%;

@include font-face("TelstraDisplay", "#{$fontDir}TelstraDisplay/TelstraDisplay-Regular");
@include font-face("TelstraDisplay", "#{$fontDir}TelstraDisplay/TelstraDisplay-Bold", bold);
@include font-face("TelstraDisplay", "#{$fontDir}TelstraDisplay/TelstraDisplay-RegularItalic", normal, italic);
@include font-face("TelstraDisplay", "#{$fontDir}TelstraDisplay/TelstraDisplay-BoldItalic", bold, italic);
@include font-face("TelstraDisplay", "#{$fontDir}TelstraDisplay/TelstraDisplay-Light", 100);
$fontTelstraDisplay: "TelstraDisplay", Helvetica, Arial, sans-serif;

$fontTelstraDisplayLineHeight: 25px;
$fontTelstraDisplayLineHeightH1: 104%;
$fontTelstraDisplayLineHeightH2: 112.5%;
$fontTelstraDisplayLineHeightH3: 125%;
$fontTelstraDisplayLineHeightH4: 122%;
$fontTelstraDisplayLineHeightH5: 125%;

@include font-face("TelstraText", "#{$fontDir}TelstraText/TelstraText-Regular");
@include font-face("TelstraText", "#{$fontDir}TelstraText/TelstraText-Bold", bold);
@include font-face("TelstraText", "#{$fontDir}TelstraText/TelstraText-RegularItalic", normal, italic);
@include font-face("TelstraText", "#{$fontDir}TelstraText/TelstraText-BoldItalic", bold, italic);
@include font-face("TelstraText", "#{$fontDir}TelstraText/TelstraText-Light", 100);
$fontTelstraText: "TelstraText", Helvetica, Arial, sans-serif;

$fontTelstraTextLineHeight: 25px;
$fontTelstraTextLineHeightH1: 104%;
$fontTelstraTextLineHeightH2: 112.5%;
$fontTelstraTextLineHeightH3: 125%;
$fontTelstraTextLineHeightH4: 122%;
$fontTelstraTextLineHeightH5: 125%;