.imageGenerator {
	padding-bottom: 0;
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Chrome/Safari/Opera */
	-khtml-user-select: none; /* Konqueror */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none;

	p {
		margin: 0 0 16px 0;

		&:not(:last-of-type) {
			margin-bottom: 0;
		}
	}

	.field {
		@include clearfix;

		margin-bottom: 24px;

		&.last {
			margin-top: 32px;
		}
	}

	.imageContainer {
		background: $colourGreyL5;
		clear: both;
		float: left;
		min-width: 450px;
		position: relative;
		text-align: center;
		width: auto;
	}

	#imageProxyContainer {
		background: $colourGreyL5;
		border: 1px $colourGreyL3 solid;
		cursor: all-scroll;
		overflow: hidden;
		position: relative;
		z-index: 1;

		> div {
			background-size: cover;
			pointer-events: none;
			position: absolute;
			height: 100%;
			width: 100%;
		}
	}

	#imageProxy {
		max-width: none;
		pointer-events: none;
		position: absolute;
	}

	#imageDisplay {
		background: $colourGreyL5;
		max-height: 100%;
		max-width: 100%;
		display: block;
	}

	.uploadField {
		clear: both;
		padding-top: 16px; //allows for controls

		& .uploader {
			float: left;
			width: 48%;

			& .uploadButton {
				background: $colourGreyL5;
				border: 3px dashed $colourTurquoiseL2;
				position: relative;
				height: 44px;
				line-height: 38px;
				text-align: center;
				width: 340px;
				cursor: pointer;

				&:hover {
					border-color: $colourTurquoiseL1;
					background: $colourTurquoiseL4;
				}

				input[type=file] {
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					position: absolute;
					opacity: 0;
					cursor: pointer;
				}
			}
		}

		& .alpha {
			float: right;
			width: 48%;
		}
	}

	input[type=range] {
		position: absolute;
	}

	#imagePositionX {
		background: transparent;
		-webkit-appearance: none;
		position: absolute;
		height: 70px;
		width: 120px;
		right: 0;
		bottom: -54px;
	}

	#imagePositionY {
		background: transparent;
		-webkit-appearance: none;
		position: absolute;
		height: 70px;
		width: 115px;
		right: -65px;
		bottom: 18px;
		-webkit-padding-after: 31px;
		-webkit-transform: rotate(90deg);
		-ms-transform: rotate(90deg);
		transform: rotate(90deg);
	}

	#imageScale {
		background: transparent;
		-webkit-appearance: none;
		position: absolute;
		height: 70px;
		width: 115px;
		right: -80px;
		top: 40px;
		-webkit-transform: rotate(-90deg);
		-ms-transform: rotate(-90deg);
		transform: rotate(-90deg);
	}

	input[type=range]:focus {
		outline: none;
	}

	input[type=range]::-webkit-slider-runnable-track {
		width: 100%;
		height: 3px;
		cursor: pointer;
		animate: 0.2s;
		background: $colourGreyL5;
		border-radius: 0;
		border: none;
	}

	input[type=range]::-webkit-slider-thumb {
		border: none;
		height: 20px;
		width: 20px;
		border-radius: 10px;
		background: $colourTurquoiseL2;
		cursor: pointer;
		-webkit-appearance: none;
		margin-top: -9.5px;
	}

	input[type=range]:hover::-webkit-slider-thumb {
		background: $colourTurquoiseL1;
	}

	input[type=range]:focus::-webkit-slider-runnable-track {
		background: $colourGreyL5;
	}

	input[type=range]::-moz-range-track {
		width: 100%;
		height: 3px;
		cursor: pointer;
		animate: 0.2s;
		background: $colourGreyL5;
		border-radius: 0;
		border: none;
	}

	input[type=range]::-moz-range-thumb {
		border: none;
		height: 20px;
		width: 20px;
		border-radius: 10px;
		background: $colourTurquoiseL2;
		cursor: pointer;
	}

	input[type=range]:hover::-moz-range-thumb {
		background: $colourTurquoiseL1;
	}

	input[type=range]::-ms-track {
		width: 100%;
		height: 3px;
		cursor: pointer;
		animate: 0.2s;
		background: transparent;
		border-color: transparent;
		color: transparent;
	}

	input[type=range]::-ms-fill-lower,
	input[type=range]::-ms-fill-upper {
		background: $colourGreyL5;
		border: none;
		border-radius: 0;
	}

	input[type=range]::-ms-thumb {
		border: none;
		height: 20px;
		width: 20px;
		border-radius: 10px;
		background: $colourTurquoiseL2;
		cursor: pointer;
	}

	input[type=range]:hover::-ms-thumb {
		background: $colourTurquoiseL1;
	}

	input[type=range]:focus::-ms-fill-lower,
	input[type=range]:focus::-ms-fill-upper {
		background: $colourGreyL5;
	}

	#imageLoading {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 60px;
		height: 60px;
		-ms-transform: translate(-50%, -50%);
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		//background: url(#{$imagesDir}ajax-loader.gif);
		background-position: center;
		background-repeat: no-repeat;
	}

	#uploadLabel1 {
		display: block;
		line-height: 38px;
	}

	#uploadLabel2 {
		display: block;
		line-height: 38px;
	}

	#imageScaleLabel {
		position: absolute;
		top: 0;
		right: -40px;
		text-align: left;
		font-weight: bold;
		font-size: 13px;
		margin: 0;
	}

	#imagePositionLabel {
		position: absolute;
		bottom: -38px;
		right: -40px;
		text-align: left;
		font-weight: bold;
		font-size: 13px;
	}

	.imageLabel {
		margin: 10px 0 0 0;
	}

	.logo {
		@include clearfix;

		float: left;
		width: 48%;
	}

	.colour {
		@include clearfix;

		float: right;
		width: 48%;

		> div:not(:last-child) {
			margin-right: 16px;
		}

		.colourButton {
			background: no-repeat center;
			cursor: pointer;
			display: inline-block;
			height: 40px;
			width: 40px;

			&.disabled {
				opacity: 0.3;
				pointer-events: none;
			}

			&:hover {
				outline: 3px solid $colourTurquoiseL1;
				outline-offset: -3px;
			}
		}
	}

	.copy {
		> div:not(:last-child) {
			margin-bottom: 24px;
		}
	}

	.templateButton {
		cursor: pointer;

		&:hover {
			outline: 3px solid $colourTurquoiseL1;
			outline-offset: -3px;
		}
	}

	.active {
		outline: 3px solid $colourTurquoiseL2;
		outline-offset: -3px;
	}

	h5.error {
		color: $colourOrangeL1;
		display: block;
	}

	#{$all-text-inputs},
	textarea,
	select {
		background: $colourWhite;
		font-family: $fontTelstraText;
		line-height: $fontTelstraTextLineHeight;
		color: $colourDefaultFont;
		display: block;
		border: 1px solid $colourGreyL5;
		font-size: $fontDefaultSize;
		padding: 8px;
		min-width: 50%;

		@include placeholder {
			color: $colourGreyL3;
			font-style: italic;
		}

		&:focus {
			border-color: $colourTurquoiseL2;
			outline-color: $colourTurquoiseL2;
		}

		&.error {
			border-color: $colourOrangeL1;
			outline-color: $colourOrangeL1;

			&:focus {
				border-color: $colourOrangeL1;
				outline-color: $colourOrangeL1;
			}
		}
	}

	.downloadButton {
		background-color: $colourTurquoiseL2;
		border: none;
		color: white;
		cursor: pointer;
		font-size: $fontDefaultSize;
		font-weight: bold;
		line-height: $fontTelstraTextLineHeight;
		padding: 15px 20px;
		text-align: left;
		width: 280px;

		i {
			float: right;
		}

		&:hover {
			background-color: $colourTurquoiseL1;
		}
	}

	.infoButton {
		border: none;
		color: $colourGreyL2;
		cursor: pointer;
		margin: 0;
		outline: none;
		padding: 0;
		position: relative;
		vertical-align: middle;

		> i {
			border: 1px solid currentColor;
			border-radius: 50%;
			color: inherit;
			display: inline-block;
			font-size: 14px;
			font-style: normal;
			font-family: $fontTelstraText;
			height: 24px;
			line-height: 1;
			padding: 5px 0 0 1px;
			position: relative;
			left: 8px;
			width: 24px;
		}

		&:active,
		&:focus,
		&:hover {
			color: $colourGreyL1;
		}

		&:active,
		&:focus {
			outline: none;
		}
	}

	.imageInfo {
		background: $colourWhite;
		box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
		font-size: $fontDefaultSize;
		outline: none;
		padding: 16px;
		position: absolute;
		left: 12px;
		top: 30px;
		text-align: left;
		width: 200px;
		z-index: 999;
	}
}
