@mixin generic-page-headings($colour, $l1Colour: null) {
	h1 {
		color: $colour;
		font-family: $fontTelstraDisplay;
		font-size: $h1DefaultSize;
		font-weight: 400;
		line-height: $fontTelstraDisplayLineHeightH1;
		margin: 0 0 40px 0;

		@if $l1Colour {
			.characterTelstraStar {
				color: $l1Colour;
			}
		}
	}

	h2 {
		color: $colour;
		font-family: $fontTelstraDisplay;
		font-size: $h2DefaultSize;
		font-weight: 400;
		line-height: $fontTelstraDisplayLineHeightH2;
		margin: 0 0 32px 0;

		@if $l1Colour {
			.characterTelstraStar {
				color: $l1Colour;
			}
		}
	}

	h3 {
		color: $colour;
		font-family: $fontTelstraDisplay;
		font-size: $h3DefaultSize;
		font-weight: 400;
		line-height: $fontTelstraDisplayLineHeightH3;
		margin: 0 0 24px 0;

		@if $l1Colour {
			.characterTelstraStar {
				color: $l1Colour;
			}
		}
	}

	h4 {
		color: $colour;
		font-family: $fontTelstraDisplay;
		font-size: $h4DefaultSize;
		font-weight: 400;
		line-height: $fontTelstraDisplayLineHeightH4;
		margin: 0 0 16px 0;

		@if $l1Colour {
			.characterTelstraStar {
				color: $l1Colour;
			}
		}
	}

	h5 {
		color: $colour;
		font-family: $fontTelstraDisplay;
		font-size: $h5DefaultSize;
		font-weight: 400;
		line-height: $fontTelstraDisplayLineHeightH5;
		margin: 0 0 8px 0;

		@if $l1Colour {
			.characterTelstraStar {
				color: $l1Colour;
			}
		}
	}
}

@mixin page-guideline-colours($colour, $colourHover, $colourBackground) {
	h2 {
		color: $colour;
	}
	a,
	.contentsList span {
		color: $colour;

		&:hover {
			border-color: $colourHover;
			color: $colourHover;
		}
	}

	.blocks {
		.boxed {
			h3 {
				color: $colour;
			}

			strong {
				color: $colour;
			}

			.circle {
				background: $colourBackground;
			}
		}
	}
}

@mixin page-glow() {
	background: rgba(53, 35, 127, 0.4);
	border-radius: 3px;
	padding: 50px;
}

@mixin buttonClose() {
	&_buttonClose {
		position: absolute;
		top: 5px;
		right: 3px;
		background-color: transparent;
		border: none;
		color: $colourGreyL2;
		font-size: 16px;
		padding: 0;

		&:active,
		&:focus {
			outline: none;
		}

		&:hover {
			color: $colourGreyL1;
		}
	}
}

.pageGuideline {

	@include generic-page-headings($colourGreyL1, $colourGreyL3);

	&.sky,
	&.blue{
		@include page-guideline-colours($colourSky60, $colourSky80, $colourSky20);
	}
	&.earth,
	&.magenta{
		@include page-guideline-colours($colourEarth60, $colourEarth80, $colourEarth20);
	}
	&.sand {
		@include page-guideline-colours($colourSand40, $colourSand60, $colourSand20);
	}

	&.green {
		@include page-guideline-colours($colourGreenL2, $colourGreenL1, $colourGreenL3);
	}

	a,
	.contentsList span {
		text-decoration: none;

		&:hover {
			:not([class*="td-icon"]) {
				text-decoration: underline;
			}
		}
	}

	p,
	li {
		a {
			&:hover {
				text-decoration: underline;
			}
		}
	}

	.noUnderline {
		text-decoration: none;

		&:hover {
			text-decoration: none;
		}
	}

	.hoverUnderline {
		&:hover {
			text-decoration: underline;
		}
	}

	.primaryImage {
		> .controls {
			h2 {
				margin: 0;
			}
		}
	}

	.backToTop {
		position: absolute;
		top: 35px;
		right: 0;
		cursor: pointer;
		color: $colourGreyL2;

		> p {
			display: inline-block;
		}

		&:hover {
			color: $colourGreyL1;

			> p {
				text-decoration: underline;
			}
		}
	}

	h1 {
		margin-bottom: 25px;
	}
	h2 {
		margin-bottom: 15px;
	}

	section {
		border-top: 1px solid $colourGreyL4;
		position: relative;

		@include mediaAboveSmallScreen {
			margin-top: 40px;
			padding-top: 40px;
		}

		@include mediaSmallScreen {
			margin-top: 24px;
			padding-top: 24px;
		}

		> h2 {
			@include mediaAboveSmallScreen {
				//max-width: percentage(885px / 2770px);
				max-width: 64%;
			}
			margin-bottom: 20px;
		}

		li {
			margin: 8px 0;

			h4 {
				margin-bottom: 5px;
			}
			div {
				p {
					&:first-child {
						margin-top: 0;
					}
				}
			}
		}

		> .content {
			margin-top: 20px;

			p {
				margin: 20px 0 0 0;

				&:first-child {
					margin-top: 0;
				}
			}

			+ .blocks {
				margin-top: 24px;
			}
		}

		> span + .content {
			margin-top: 40px;
		}

		> .blocks {
			@include mediaAboveSmallScreen {
				@include align-content(flex-start);
				@include align-items(stretch);
				@include display(flex);
				@include flex-direction(row);
				@include flex-wrap(wrap);
				@include justify-content(space-between);
				$blocks-gutter: 15px;

				.no-flexbox & {
					@include clearfix();

					> div {
						float: left;
						margin-left: $blocks-gutter;

						&:first-child {
							margin-left: 0;
						}

						&.break {
							clear: right;

							+ div {
								clear: left;
								margin-left: 0;
							}
						}
					}
				}

				> div {
					margin-bottom: 16px;

					> img + h3 {
						margin-top: 16px;
					}

					&.boxed {
						background: $colourGreyL5;

						font-size: $fontSmallSize;
						padding: 16px;

						h3 {
							font-size: $fontSmallSize;
							margin: 0 0 16px 0;
						}

						.circle {
							border-radius: 50%;
							color: $colourWhite;
							font-size: 80%;
							font-weight: normal;
							@include inline-block;
							line-height: 1em;
							margin-top: -2px;
							padding: 3px 6px 3px 5px;
							position: relative;
							text-align: center;
							vertical-align: middle;
						}
					}
				}

				.full {
					width: 100%;
				}

				.quarter {
					$width: percentage(1 / 4);
					@include calc(width, "#{$width} - #{$blocks-gutter}");
				}

				.third {
					$width: percentage(1 / 3);
					@include calc(width, "#{$width} - #{$blocks-gutter}");
				}

				.half {
					$width: percentage(1 / 2);
					@include calc(width, "#{$width} - #{$blocks-gutter}");
				}

				.twoThirds {
					$width: percentage(2 / 3);
					@include calc(width, "#{$width} - #{$blocks-gutter}");
				}
			}

			@include mediaSmallScreen {
				> div {
					margin-top: 16px;

					&:first-child {
						margin-top: 0;
					}
				}
			}

			.blockNormal {

				&.contrast {
					color: $colourWhite;

					h3, h4 {
						color: $colourWhite;
					}
				}

				&.largeTitled {
					h3 {
						font-family: $fontTelstraDisplay;
						font-size: 40px;
						font-weight: normal;
						line-height: $fontTelstraDisplayLineHeightH3;
					}
				}

				&.padding {
					padding: 16px;
				}

				.buttonAudioPlayer {
					bottom: 16px;
					left: 16px;
					position: absolute;
				}

				.content {
					ol, ul {
						margin-left: 0;
						padding-left: 20px;
					}

					p {
						margin: 20px 0 0 0;

						&:first-child {
							margin-top: 0;
						}
					}
				}

				h3 + .content,
				h4 + .content,
				div + .content {
					margin-top: 24px;
				}

				div + h3,
				div + h4 {
					margin-top: 16px;
				}

			}

			.blockColour {
				padding: 15px 15px 60px 15px;

				> h3 {
					color: #fff;
					margin-bottom: 20px;

					> span {
						font-weight: normal;
					}
				}

				.colours {
					@include clearfix;

					color: $colourWhite;
					font-size: 13px;

					> div {
						float: left;
						width: percentage(1 / 3);

						p {
							line-height: 1em;
							margin: 0 0 3px 0;
						}
					}

					table {
						td,
						th {
							line-height: 1em;
							padding: 0 0 3px 0;
						}

						th {
							text-align: left;
							width: 16px;
						}
					}
				}

				&.contrast {
					> h3 {
						color: $colourGreyL1;
					}

					.colours {
						color: $colourGreyL1;
					}
				}
			}

			> div.hasSound {
				padding-bottom: 50px;
				position: relative;
			}

		}

		.primaryImage {
			text-align: right;
			padding-right: 2%;

			img {
				display: inline-block;
				max-width: 300px;
			}
		}
	}
}

.pageGuidelineSummary {
	@include generic-page-headings($colourGreyL1);

	&.blue, &.sky, &.magenta {
		a {
			color: $colourSky60;

			&:hover {
				color: $colourSky80;
			}
		}
	}

	&.green {
		a {
			color: $colourGreenL2;

			&:hover {
				color: $colourGreenL1;
			}
		}
	}

	.containerTitleAndMeta {
		h1 {

			margin: 0;
			padding: 0;
		}
	}

	.primaryImage {
		> .controls {
			h2 {
				margin: 0;
			}
		}
	}

	> .meta {
		> .content {
			max-width: percentage(1 / 3);
		}
	}


	section {
		border-top: 1px solid $colourGreyL4;
		position: relative;

		@include clearfix;

		@include mediaAboveSmallScreen {
			margin-top: 40px;
			padding-top: 40px;
		}

		@include mediaSmallScreen {
			margin-top: 24px;
			padding-top: 24px;
		}

		$guideline-summary-gutter: 32px;
		.lhs {
			float: left;
			width: percentage(1 / 3);

			> .content {
				margin-top: 20px;

				p {
					margin: 20px 0 0 0;

					&:first-child {
						margin-top: 0;
					}
				}

				+ a {
					@include inline-block;
					margin: 40px 0 0 0;
				}
			}

			> .links {
				bottom: 0;
				left: 0;
				position: absolute;

				> div {
					margin-top: 8px;

					&:first-child {
						margin-top: 0;
					}
				}

				a {
					color: $colourDefaultFont;
					text-decoration: none;
					@include transition(color 0.3s);

					&:hover {
						color: inherit;
					}
				}
			}
		}

		.rhs {
			float: right;
			$width: percentage(2 / 3);
			@include calc(width, "#{$width} - #{$guideline-summary-gutter}");
		}
	}
}

.colours,
.images,
.logos,
.templates {
	.containerContainers,
	.containerElements {
		@include mediaAboveSmallScreen {
			@include align-content(flex-start);
			@include align-items(stretch);
			@include display(flex);
			@include flex-direction(row);
			@include flex-wrap(wrap);
			@include justify-content(flex-start);
			$blocks-gutter: 16px;

			> div {
				$width: percentage(1 / 4);
				@include calc(width, "#{$width} - #{$blocks-gutter}");
				margin-bottom: 32px;
				margin-left: calc(#{$blocks-gutter} * 4 / 3);

				&:first-child,
				&:nth-child(4n + 1) {
					margin-left: 0;
				}
			}
		}

		@include mediaSmallScreen {
			> div {
				display: block;
				margin-bottom: 16px;
			}
		}

		&.viewingTemplateInfo {
			.container {
				&:not(.activeContainer) {
					.preview {
						opacity: 0.3;
					}
					.templateInfo {
						display: none;
					}
				}

				&.activeContainer {
					position: relative;

					.templateInfo {
						background: $colourWhite;
						box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
						padding: 15px;
						position: absolute;
						width: 150%;
						left: -25%;
						top: 102%;
						z-index: 999;

						&_description {
							white-space: pre-wrap;
						}

						@include buttonClose();

						&_details {
							&-title {
								margin: {
									top: 5px;
									bottom: 5px;
								}
								font-size: 15px;
							}
						}
					}
				}
			}
		}
	}
}

.logos {
	.preview {
		img {
			max-height: 90%;
			max-width: 80%;
		}
	}
}

.icons {
	.element {
		.preview {
			opacity: 1;
			@include transition(opacity 0.5s ease-in-out);
		}
	}

	&.viewingIconInfo {
		.element {
			&:not(.viewingIconInfo) {
				.preview {
					opacity: 0.3;
				}
			}

			&.viewingIconInfo {
				position: relative;

				.iconInfo {
					background: #FFF;
					box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
					padding: 15px;
					position: absolute;
					left: -25%;
					width: 150%;
					top: 102%;
					z-index: 999;

					@include buttonClose();

					> h5 {
						font: {
							size: 14px;
							weight: bold;
							family: $fontTelstraDisplay;
						}
						margin: 0;
						text-transform: capitalize;
					}

					span {
						display: inline-block;
						font: {
							size: 12px;
							weight: normal;
							family: $fontTelstraText;
						}
						margin: {
							right: 4px;
							bottom: 4px;
						}
						padding: 2px 4px;
						background-color: $colourGreyL5;
						border-radius: 5px;

						&:last-of-type {
							margin-right: 0;
							margin-bottom: 12px;
						}
					}

					> p {
						margin: 0 0 12px 0;
						font: {
							size: 12px;
							weight: normal;
							family: $fontTelstraText;
						}

						&:last-of-type {
							margin: 0;
						}
					}
				}
			}
		}
	}

	.containerContainers {
		.iconBaseTitle {
			font-size: 14px;
			color: $colourGreyL1;
			margin: 0;
		}

		.preview {
			position: relative;

			.downloadOptionsText {
				display: none;
				position: absolute;
				bottom: 10%;
				left: 28%;
			}

			.buttonDownload {
				position: absolute;
				top: 0;
				left: 0;
				z-index: 5;
				@include size(100%);
			}

			&:hover {
				background: $colourTurquoiseL3;

				.downloadOptionsText {
					display: block;
					color: #FFFFFF;
				}

				.negative {
					display: block;
					background: transparent;
				}

				.positive {
					display: none;
				}
			}

			.negative {
				display: none;
			}
		}

		@include mediaAboveSmallScreen {
			@include align-content(flex-start);
			@include align-items(stretch);
			@include display(flex);
			@include flex-direction(row);
			@include flex-wrap(wrap);
			@include justify-content(flex-start);
			$blocks-gutter: 16px;

			> div {
				$width: percentage(1 / 4);
				@include calc(width, "#{$width} - #{$blocks-gutter}");
				margin-bottom: 30px;
				margin-left: calc(#{$blocks-gutter} * 4 / 3);

				&:first-child,
				&:nth-child(4n + 1) {
					margin-left: 0;
				}
			}
		}

		@include mediaSmallScreen {
			> div {
				display: block;
				margin-bottom: 15px;
			}
		}
	}

	.containerElements {
		h3 {
			color: $colourGreyL1;
			font-size: 14px;
			font-weight: bold;
			line-height: $fontTelstraDisplayLineHeight;
			margin: 0 0 8px 0;
			padding-right: 20px;
			position: relative;
			overflow: visible;
			//width: 100%;
			@include ellipsis(100%);

			span {
				@include ellipsis;
			}
		}

		@include mediaAboveSmallScreen {
			@include align-content(flex-start);
			@include align-items(stretch);
			@include display(flex);
			@include flex-direction(row);
			@include flex-wrap(wrap);
			@include justify-content(flex-start);
			$blocks-gutter: 16px;

			> div {
				$width: percentage(1 / 6);
				@include calc(width, "#{$width} - #{$blocks-gutter}");
				margin-bottom: 20px;
				margin-left: calc(#{$blocks-gutter} * 6 / 5);

				&:first-child,
				&:nth-child(6n + 1) {
					margin-left: 0;
				}

				.preview {
					position: relative;
					> div {
						padding-top: 71%;

						> img {
							max-width: 55%;
							max-height: 56%;
						}
					}

					.downloadOptionsText {
						display: none;
						position: absolute;
						bottom: 13px;
						left: 10px;
					}

					.buttonDownload {
						position: absolute;
						top: 0;
						left: 0;
						z-index: 5;
						@include size(100%);
					}

					&:hover {
						.downloadOptionsText {
							display: block;
							color: #FFFFFF;
						}
						> div {
							background: $colourTurquoiseL3;
						}
					}

					img.negative {
						display: none;
					}

					&.hoverNegative {
						> div {
							> img {
								display: none;

								&.negative {
									display: block;
								}
							}
						}
					}
				}
			}
		}

		@include mediaSmallScreen {
			> div {
				display: block;
				margin-bottom: 15px;
			}
		}
	}

	.buttonTopOfPage {
		bottom: 120px;
		color: $colourGreyL2;
		cursor: pointer;
		opacity: 1;
		position: fixed;
		@include transition(opacity 0.3s ease-in);

		&:hover {
			color: $colourGreyL1;

			span {
				text-decoration: underline;
			}
		}
	}
}

.images {
	> form {
		margin-bottom: 50px;

		> label {
			cursor: pointer;
			margin-right: 6px;

			input,
			span {
				@include inline-block;
				vertical-align: middle;
			}
		}
	}

	.element {
		> .preview {
			> div {
				//background: url(#{$imagesDir}bgPhotoDark.gif);

				> img {
					max-height: 100%;
					max-width: 100%;
					@include transition(all 0.3s);
				}
			}
		}
	}

	&.viewingImageInfo {
		.element {
			&:not(.viewingImageInfo) {
				.preview {
					img {
						opacity: 0.3;
					}

					> div {
						//background: url(#{$imagesDir}bgPhotoDark.gif);
					}
				}
			}

			&.viewingImageInfo {
				position: relative;

				.imageInfo {
					background: #fff;
					box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
					padding: 15px;
					position: absolute;
					left: 10px;
					top: 102%;
					z-index: 999;

					> h5 {
						font-size: 14px;
						font-weight: bold;
						margin: 0 0 15px 0;
						text-transform: capitalize;
					}

					> p {
						margin: 0 0 12px 0;

						small {
							display: block;
							font-size: 12px;
						}

						+ p {
							margin: 0;
						}
					}

					a {
						color: $colourTurquoiseL2;
					}
				}
			}
		}
	}

	.preview {
		position: relative;

		> div {
			margin: {
				top: 0;
				bottom: 0;
			}

		}

		&_modalTrigger {
			position: absolute;
			top: 0;
			left: 0;
			cursor: pointer;
			border: none;
			background: {
				//image: url($imagesDir + 'plus.png');
				position: center center;
				color: rgba(0, 0, 0, 0.5);
				repeat: no-repeat;
			}
			@include size(100%);

			&:active,
			&:focus {
				outline: none;
			}
		}
	}
}

.layout-404, .layout-403 {
	.wrapper-404, .wrapper-403 {
		padding-bottom: 48px;

		h1 {
			color: $colourGreyL1;
			font-family: $fontTelstraDisplay;
			font-size: $h1DefaultSize;
			font-weight: 400;
			line-height: $fontTelstraDisplayLineHeightH1;
			margin: 0 0 40px 0;
		}

		a {
			color: $colourSky60;
			text-decoration: none;

			&:hover {
				color: $colourSky80;
				text-decoration: underline;
			}
		}
	}
}

.pageLogin,
.pageRegister,
.pageContactUs,
.pageSearchPartner {
	@include horiztonalCenter;

	background: $colourWhite;
	margin: 80px 0;
	padding: 48px;
	border-radius: 20px;
	border: 1px solid $colourGrey60;

	> h1 {
		@include headingFeature;
	}

	> form {
		margin: 0 auto;
		width: 315px;

		button {
			@include button-with-arrow($colourEarth60, $colourWhite, $colourEarth80);
			border-radius: 10px;
			//width: 100%;
		}

		#{$all-text-inputs},
		select,
		textarea {
			color: $colourDefaultFont;
			display: block;
			font-family: $fontTelstraText;
			font-size: $fontDefaultSize;
			line-height: $fontTelstraTextLineHeight;
			outline: none;
			padding: 8px;
			width: 100%;
			border-radius: 4px;
			border: 1px solid $colourGrey80;
			caret-color: $colourEarth60;
			transition-property: color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-backdrop-filter;
			transition-timing-function: cubic-bezier(.4,0,.2,1);
			transition-duration: .15s;
			&:hover {
				background-color: $colourEarth20;
				border: 1px solid $colourEarth60;
			}
			&:focus {
				background-color: white;
				border: 2px solid $colourEarth60;
				margin: -1px;
			}

			@include placeholder {
				color: $colourGreyL3;
			}
		}

		textarea {
			resize: none;
		}

		.formRow {
			clear: both;
			margin-bottom: 24px;

			&:last-of-type {
				margin-bottom: 0;
			}

			p {
				margin: 16px 0 0 0;
			}
		}

		.formInput {
			margin-bottom: 24px;
			position: relative;

			label {

			}

			input {
				height: 40px;
				width: 100%;
				caret-color: $colourEarth60;
				padding: 12px 20px;
				font-size: 1.125rem;
				line-height: 1.75rem;
				border-radius: 4px;
				border: 1px solid $colourGrey80;
				transition-property: color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-backdrop-filter;
				transition-timing-function: cubic-bezier(.4,0,.2,1);
				transition-duration: .15s;
				&:hover {
					background-color: $colourEarth20;
					border: 1px solid $colourEarth60;
				}
				&:focus {
					background-color: white;
					border: 2px solid $colourEarth60;
					margin: -1px;
				}
			}

			&.green{
				input {
					caret-color: $colourGreenL2;
					border: 1px solid $colourGreenL1;
					&:hover {
						background-color: $colourGreenL4;
						border: 1px solid $colourGreenL2;
					}
					&:focus {
						border: 2px solid $colourGreenL2;
					}
				}
			}

			input:not(:placeholder-shown) ~ .floatingLabel,
			input:focus ~ .floatingLabel,
			textarea:focus ~ .floatingLabel,
			input:valid ~ .floatingLabel,
			textarea:valid ~ .floatingLabel{
				color: $colourGreyL1;
				font-size: 14px;
				top: -16px;
				left: 8px;
				opacity: 1;
			}

			.selectLabelShrink{
				position: relative;
				color: $colourGreyL1;
				//top: -16px;
				left: 8px;
				opacity: 1;
			}
			.floatingLabel {
				color: $colourGreyL3;
				pointer-events: none;
				position: absolute;
				left: 8px;
				top: 9px;

				@include ellipsis(calc(100% - 16px));
				@include transition(all 0.2s ease);
			}

			//@include gradientUnderline;

			&::after {
				opacity: 0;

				@include transition(opacity 0.3s ease-in-out);
			}

			&.focusWithin,
			&:hover {
				&::after {
					opacity: 1;
				}
			}

			&:not(.focusWithin) {
				.ng-invalid.ng-dirty,
				.ng-invalid.ng-touched {
					background-color: $colourRedDanger;
					border-bottom: 1px solid $colourRedError;
					color: $colourRedError;
					margin-bottom: -1px;
				}
			}
			> .error {
				background-color: $colourRedDanger;
				border-bottom: 1px solid $colourRedError;
				color: $colourRedError;
				margin-bottom: -1px;
			}

		}

		.passwordMessage {
			color: $colourRedError;
			font-size: 14px;
			margin: -16px 0 16px 0;

			@include transition(all 0.3s);
		}
	}
}

.pageGeneric,
.pageLogout,
.pageRegisterPending,
.pageRegisterSuccess {
	@include horiztonalCenter;

	background: $colourWhite;
	margin: 80px 0;
	padding: 48px;
	max-width: 400px;

	> h1 {
		@include headingFeature;
	}

	> p {
		margin: 0 0 24px 0;

		&:last-of-type {
			margin: 0;
		}
	}

	a {
		color: $colourEarth60;
		text-decoration: none;

		&:hover {
			color: $colourEarth80;
			text-decoration: underline;
		}
	}

	> form {
		margin-top: 32px;
	}
}
//
.pageLostPassword {

	p {
		color: $colourGreyL1;
		font-size: $fontSmallSize;
		margin: 24px 0;
		padding: 8px;
	}

	form {
		//width: 260px;
		width: 100%;

		button {
			@include button-with-arrow($colourEarth60, $colourWhite, $colourEarth80);;

			width: 100%;
		}

		#{$all-text-inputs},
		select,
		textarea {
			border: none;
			color: $colourDefaultFont;
			display: block;
			font-family: $fontTelstraText;
			font-size: $fontDefaultSize;
			line-height: $fontTelstraTextLineHeight;
			outline: none;
			padding: 8px;
			width: 100%;

			@include placeholder {
				color: $colourGreyL3;
			}
		}

		p {
			margin: 16px 0 0 0;
			padding: 0;
		}

		& fieldset {
			border: none;
			margin: 0;
			padding: 0;

			.field.checkbox {
				margin-bottom: 24px;
				font-size: $fontSmallSize;
			}

			.field.text {
				border-bottom: 1px solid $colourGreyL3;
				margin-bottom: 24px;
				position: relative;

				input {
					height: 40px;
					width: 100%;
				}

				//ie11 hack for floating label
				label {
					color: $colourGreyL1;
					display: inline-block;
					font-size: $fontSmallSize;
					pointer-events: none;
					position: absolute;
					top: -16px;
					left: 8px;
				}

				input:placeholder-shown ~ label {
					color: $colourGreyL3;
					font-size: $fontDefaultSize;
					max-width: calc(100% - 16px);
					overflow: hidden;
					left: 8px;
					top: 9px;
					text-overflow: ellipsis;
					white-space: nowrap;

					@include transition(all 0.2s ease);
				}

				input:focus ~ label,
				input:not(:placeholder-shown) ~ label {
					color: $colourGreyL1;
					font-size: $fontSmallSize;
					top: -16px;
					left: 8px;
				}

				&::after {
					bottom: -3px;
					content: "";
					height: 3px;
					left: 0;
					position: absolute;
					width: 100%;
					opacity: 0;

					@include linear-gradient(90deg, #F7AB59 0%, #EA5153 35%, #C24279 70%);

					@include transition(opacity 0.3s ease-in-out);
				}

				&.focusWithin,
				&:hover {
					&::after {
						opacity: 1;
					}
				}

				&:focus-within {
					&::after {
						opacity: 1;
					}
				}

			}
		}
	}
}

.pageDownloads {
	@include generic-page-headings($colourGreyL1);
	padding-bottom: 30px;

	.wrapper {
		background-color: white;
	}

	h2 {
		color: $colourEarth60;
	}

	h4 {
		@include ellipsis;
		width: 100%;
	}

	h5 {
		@include ellipsis(calc(100% - 20px));
		color: $colourGreyL1;
		font-size: $h5DefaultSize;
		font-weight: normal;
		margin: 8px 0 0 0;
	}

	.containerContainers {
		~ div {
			margin-top: 10px;
		}
	}

	.searchContainer {
		display: flex;
		float: left;
		margin-bottom: 24px;

		.search {
			border-bottom: 1px solid $colourGreyL3;
			padding: 0 40px 0 0;
			margin-right: 20px;
			position: relative;
			width: 260px;

			> input {
				background: transparent;
				border: none;
				color: $colourGreyL1;
				font-family: $fontTelstraText;
				font-size: 15px;
				font-weight: 100;
				line-height: 1.15em;
				padding: 12px 0 12px 12px;
				outline: none;
				width: 220px;

				@include placeholder {
					color: $colourGreyL3;
					font-style: italic;
				}

				&::-ms-clear { /*IE10+ remove the X to clear*/
					width : 0;
					height: 0;
				}
			}

			> .buttonClearSearch,
			> .buttonSearch {
				position: absolute;
				right: 7px;
				top: 7px;
				z-index: 6;
			}

			.buttonSearch {
				opacity: 1;
			}

			.buttonClearSearch {
				opacity: 0;
				@include transition(opacity 0.25s ease);
			}

			&.focusWithin,
			&.showClearIcon {
				.buttonSearch {
					opacity: 0;
					@include transition(opacity 0.25s ease);
				}

				.buttonClearSearch {
					opacity: 1;
				}
			}

			&::after {
				bottom: -3px;
				content: "";
				height: 3px;
				left: 0;
				opacity: 0;
				position: absolute;
				width: 100%;


				@include linear-gradient(90deg, #F7AB59 0%, #EA5153 35%, #C24279 70%);
				@include transition(opacity 0.3s ease-in-out);
			}

			&.focusWithin,
			&:hover {
				color: $colourGreyL1;

				&::after {
					opacity: 1;
				}
			}
		}
	}
}

.pageHome {
	@include generic-page-headings($colourGreyL1, $colourGreyL3);

	.quickLink {

		h2 {
			margin: 10px 10px 10px 0;
			color: $colourEarth60;
			font-weight: 400;
		}

		a {
			font-size: 18px;
			color: $colourEarth60;
			text-decoration: none;
		}
		a:hover {
			text-decoration-color: $colourEarth60;
			text-decoration: underline;
		}

		ul {
			padding-inline-start: 15px;
		}
		ul li {
			line-height: 40px;
			padding-left: 5px;
		}
		ul li::marker {
			font-size: 21px;

		}
	}

	.mainContent {
		float: left;
		width: 68%;
	}
	.downloadSectionWrapper {
		width: 30%;
		float: right;
		padding: 10px;
	}
	.referenceButton {
		background: $colourSky60;
		color: $colourWhite;
		display: block;
		font-size: $fontDefaultSize;
		font-weight: bold;
		margin-bottom: 20px;
		padding: 15px;
		text-align: center;
		text-decoration: none;

		span {
			vertical-align: top;

			@include ellipsis(calc(100% - 30px));
		}

		&:hover {
			background: $colourSky80;
		}
	}

	.guidelineDownload {
		background: $colourSky60;
		border: none;
		padding: 10px;
		color: white;
		width: 100%;
		margin-bottom: 10px;
		border-radius: 10px;
		display: flex;
		align-items: center;
		justify-content: center;

		&.orange {
			background: $colourEarth60;
			&:hover {
				background: $colourEarth80 !important;
				cursor: pointer;
			}
		}

		&:hover {
			background: $colourSky80;
			cursor: pointer;
		}

		span {
			float: left;
			line-height: 30px;
			font-size: 18px;
		}

		i {
			font-size: 20px;
			color: white;
			float: right;
			padding-left: 15px;
			line-height: 30px;
		}
	}

	.updatesButton {
		border: 2px solid $colourSky60;
		color: $colourSky60;
		display: block;
		font-size: $fontDefaultSize;
		font-weight: bold;
		margin-bottom: 30px;
		padding: 15px 30px;
		text-align: left;
		text-decoration: none;

		span {
			vertical-align: top;

			@include ellipsis(calc(100% - 30px));
		}

		i {
			 float: right;
		}

		&:hover {
			border: 2px solid $colourSky80;
			color: $colourSky80;
		}
	}

	@include mediaAboveSmallScreen {
		.left {
			width: 60%;
			float: left;
		}
		.right {
			width: 35%;
			float: right;
		}
	}
}

.pageTrainingHolder {
	@include generic-page-headings($colourGreyL1);
	padding-bottom: 32px;

	.trainingClass {
		border-top: 1px solid $colourGreyL5;
		margin-top: 30px;
		padding-top: 20px;

		&:first-child {
			border-top: none;
			margin-top: 0;
			padding-top: 0;
		}

	}
}

.pageSearchResults {
	padding: 30px 0;

	&-wrapper {
		@include outer-container();
	}

	h1 {
		color: $colourGreyL1;
		font-family: $fontTelstraDisplay;
		font-size: $h1DefaultSize;
		font-weight: 400;
		line-height: $fontTelstraDisplayLineHeightH1;
		margin: 0 0 40px 0;
	}

	//overwrite colours for search results
	.icons .containerElements > div .preview:hover > div {
		background: $colourOrangeL3;
	}

	.imageModal {
		&_form {
			&_field {
				option {
					&:hover {
						background-color: $colourOrangeL2 !important;
					}
				}

				&::after {
					@include linear-gradient(90deg, #F7AB59 0%, #EA5153 35%, #C24279 70%);
				}
			}

			&_action {
				&-positive {
					@include button-with-arrow($colourOrangeL2, $colourWhite, $colourOrangeL1);
				}

				&-negative {
					color: $colourOrangeL2;

					&:hover {
						color: $colourOrangeL1;
					}
				}
			}
		}

		&_keywordsContainer {
			&_expand {
				color: $colourOrangeL2;

				&:hover {
					color: $colourOrangeL1;
				}
			}
		}

		&_text {
			a {
				color: $colourOrangeL2;

				&:hover {
					color: $colourOrangeL1;
				}
			}
		}

		&_formats {
			&_button {
				color: $colourOrangeL2;

				&:hover {
					color: $colourOrangeL1;
				}
			}
		}
	}
}

.pageSitemap {
	h1 {
		color: $colourGreyL1;
		font-family: $fontTelstraDisplay;
		font-size: $h1DefaultSize;
		font-weight: 400;
		line-height: $fontTelstraDisplayLineHeightH1;
		margin: 0 0 40px 0;
	}
}

.imageModal {
	$textColour: $colourGreyL1;

	strong {
		font-weight: bold;
	}

	&_imageContainer {
		height: 350px;
		position: relative;
		margin-bottom: 16px;

		> img {
			position: absolute;
			top: 50%;
			left: 50%;
			@include transform(translate(-50%, -50%));
		}
	}

	&_image {
		max-width: 100%;
		max-height: 100%;
	}

	&_title {
		font-size: $fontDefaultSize;
		margin: {
			top: 8px;
			bottom: 16px;
		}
		color: $textColour;
	}

	&_keywordsContainer {
		margin: {
			top: 8px;
			bottom: 8px;
		}

		&_expand {
			background-color: $colourWhite;
			border: none;
			color: $colourTurquoiseL2;
			font-size: $fontSmallSize;
			padding: 5px;
			margin: 0;
			cursor: pointer;

			&:focus,
			&:active {
				outline: none;
			}

			&:hover {
				color: $colourTurquoiseL1;
			}
		}

		&-collapsed {
			.imageModal_keywords {
				height: 24px;
			}
		}
	}

	&_keywords {
		@include clearfix();
		overflow: hidden;

		> span {
			float: left;
			background-color: $colourGreyL5;
			padding: {
				left: 6px;
				right: 6px;
			}
			margin: {
				right: 6px;
				bottom: 6px;
			}
			color: $textColour;
			font-size: $fontSmallSize;
		}
	}

	&_text {
		margin-top: 0;
		margin-bottom: 16px;
		font-size: $fontSmallSize;

		a {
			color: $colourTurquoiseL2;
			text-decoration: none;

			&:hover {
				color: $colourTurquoiseL1;
				text-decoration: underline;
			}
		}
	}

	&_formats {
		@include clearfix();
		padding: 1px;
		margin-bottom: 16px;

		> button {
			float: left;
		}

		&_button {
			background-color: transparent;
			border: none;
			color: $colourTurquoiseL2;
			cursor: pointer;
			font-size: $fontDefaultSize;
			margin: {
				top: 0;
				right: 32px;
				bottom: 0;
				left: 0;
			}
			padding: 0;

			i {
				margin-right: 3px;
			}

			&:focus,
			&:active {
				outline: none;
			}

			&:hover {
				color: $colourTurquoiseL1;

				span {
					text-decoration: underline;
				}
			}
		}
	}

	&_form {
		$fontSize: $fontSmallSize;

		h2 {
		    margin-bottom: 24px;
		}

		&_field {
			margin-bottom: 32px;
			position: relative;

			label {
				display: block;
				font-size: $fontSize;
			}

			input,
			select,
			textarea {
				border: 0;
				border-bottom: 1px solid $colourGreyL3;
				font-family: $fontTelstraText;
				font-size: $fontSize;
				line-height: $fontTelstraTextLineHeight;
				outline: none;
				padding: 8px;
				width: 100%;

				@include appearance(none);
			}

			select {
				background-color: $colourWhite;
				border-radius: 0;
			}

			option {
				&:hover {
					background-color: $colourTurquoiseL2 !important;
					color: $colourWhite;
				}
			}

			textarea {
				min-height: 32px;
				resize: none;
			}

			p {
				font-size: $fontSize;
			}

			&::after {
				bottom: -2px;
				content: "";
				height: 3px;
				left: 0;
				opacity: 0;
				position: absolute;
				width: 100%;

				@include linear-gradient(90deg, #009792 0%, #23B8D6 35%, #C6D86b 70%);
				@include transition(opacity 0.3s ease-in-out);
			}

			&.focusWithin,
			&:hover {
				&::after {
					opacity: 1;
				}
			}

			&-estimatedEndDate {
				width: 200px;
			}

			&-usageLocation {
				width: 305px;

				select {
					option {
					    padding: 2px;
					}
				}

				&::after {
					bottom: 0;
				}
			}

			&-reminderEmails {
				textarea {
					margin-bottom: -6px;
				}
			}

			@include mediaSmallScreen {
				width: 100%!important;
			}
		}

		&_message {
			margin: {
				top: 8px;
				bottom: 0;
			}
		}

		&_action {
			border: none;
			cursor: pointer;
			font-size: $fontDefaultSize;
			margin-right: 16px;
			padding: 0;
			vertical-align: middle;

			&:focus,
			&:active {
				outline: none;
			}

			&-positive {
				outline: none;
				padding: 0;
				vertical-align: middle;
				@include button-with-arrow($colourTurquoiseL2, #FFFFFF, $colourTurquoiseL1);
			}

			&-negative {
				background-color: transparent;
				color: $colourTurquoiseL2;
				padding: 10px 12px;
				@include transition(color 0.3s ease-in);

				&:hover {
					color: $colourTurquoiseL1;
					text-decoration: underline;
				}
			}
		}

		&_notice {
			margin: {
				top: 6px;
				bottom: 0;
			}
			position: absolute;
			width: 670px;

			&-error {
				color: $colourRedError;
			}

			&-warning {
				color: $colourOrangeL2;
			}
		}
	}
}

.noScroll {
	overflow: hidden;
}

.pageContactUs{
	.formRow {
		button {
			span {
				width: 86%;
			}
		}
	}
}

.pageContactUs,
.pageSearchPartner {
	margin: 0;
	padding: 40px;
	width: 80%;
	position: static;
	left: unset;
				/* -webkit-transform: translateX(-50%); */
	-moz-transform: none;
	-ms-transform: none;
	-o-transform: none;
	transform: none;
	form {
		.formInput {
			&.noMargin {
				margin-bottom: 0;
			}
		}
		.hint {
			font-size: 14px;
		}
		textarea {
			resize: vertical;
		}
		button {
			margin-top: 35px;
		}
		.formRow:last-of-type {
			text-align: left;
		}

		.submitButton {
			width: 100px;
		}
	}

	.uploadField {
		clear: both;
		padding-top: 16px; //allows for controls

		& .uploader {
			float: left;
			width: 100%;

			h5.error {
				color: $colourOrangeL1;
				display: block;
			}

			& .uploadButton {
				background: $colourSand20;
				border: 3px dashed $colourEarth60;
				position: relative;
				height: 80px;
				line-height: 74px;
				text-align: center;
				width: 340px;
				cursor: pointer;

				&:hover,
				&.is-dragover{
					border-color: $colourEarth80;
					background: $colourEarth20;
				}

				input[type=file] {
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					position: absolute;
					opacity: 0;
					cursor: pointer;
				}
			}

			.uploadedContainer {
				width: 100%;

				.fileName {
					float: left;
					margin: 2px;
					display: inline-block;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					max-width: calc(100% - 77px);
				}

				.removeFileLink {
					width: auto;
					float: left;
					font-size: 16px;
					color: $colourSky60;
					text-decoration: underline;
					padding-left: 20px;
				}

				.removeFileLink:hover {
					color: $colourSky80;
					cursor: pointer;
				}
			}
		}

		& .alpha {
			float: right;
			width: 48%;
		}
	}

	button[type="submit"] {

	}
	.submitButton {
		background: $colourSky60;
		border: none;
		padding: 10px;
		color: white;
		width: 150px !important;
		margin-bottom: 20px;
		border-radius: 10px;
	}
}

.pageNominationFormQuestion {
	background: $colourWhite;

	> h1 {
		@include headingFeature;
	}

	h4 {
		@include headingFeature;
		font-size: $h4DefaultSize;
		font-weight: bold;
	}

	> p {
		margin: 0 0 24px 0;

		&:last-of-type {
			margin: 0;
		}
	}

	&.sky,
	&.blue,
	&.magenta{
		@include page-guideline-colours($colourSky60, $colourSky80, $colourSky20);
	}
	&.earth{
		@include page-guideline-colours($colourEarth60, $colourEarth80, $colourEarth20);
	}

	&.green {
		@include page-guideline-colours($colourGreenL2, $colourGreenL1, $colourGreenL3);
		form {
			.formRow {
				h2{
					color: $colourGreenL2;
				}
			}
			.formInput {

				input {
					caret-color: $colourGreenL2;
					border: 1px solid $colourGreenL1;
					&:hover {
						background-color: $colourGreenL4;
						border: 1px solid $colourGreenL2;
					}
					&:focus {
						border: 2px solid $colourGreenL2;
					}
				}
			}
		}
	}

	a {
		//color: $colourBlueL2;
		text-decoration: none;

		&:hover {
			//color: $colourBlueL1;
			text-decoration: underline;
		}
	}

	form, div.inviteForm {
		margin-top: 32px;

		&.narrow-form {
			width: 590px;
		}

		#{$all-text-inputs},
		select {
			color: $colourDefaultFont;
			display: block;
			font-family: $fontTelstraText;
			font-size: $fontDefaultSize;
			line-height: $fontTelstraTextLineHeight;
			outline: none;
			padding: 8px;
			width: 100%;
			border-radius: 4px;
			border: 1px solid $colourGrey80;
			caret-color: $colourSky60;
			transition-property: color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-backdrop-filter;
			transition-timing-function: cubic-bezier(.4,0,.2,1);
			transition-duration: .15s;
			&:hover {
				background-color: $colourSky20;
				border: 1px solid $colourSky60;
			}
			&:focus {
				background-color: white;
				border: 2px solid $colourSky60;
				margin: -1px;
			}

			@include placeholder {
				color: $colourGreyL3;
			}
		}

		textarea {
			color: $colourDefaultFont;
			display: block;
			font-family: $fontTelstraText;
			font-size: $fontDefaultSize;
			line-height: $fontTelstraTextLineHeight;
			outline: none;
			padding: 8px;
			width: 100%;

			@include placeholder {
				color: $colourGreyL3;
			}
		}

		.formRow {
			clear: both;
			margin-bottom: 24px;
			display: block;

			&:last-of-type {
				margin-bottom: 0;
			}

			p {
				margin: 16px 0 0 0;
			}

			h2 {
				color: $colourSky80;
				margin-bottom: 10px;
				font-weight: 400;
			}

		}

		.formInput {
			margin-bottom: 20px;
			position: relative;
			clear: both;

			input {
				caret-color: $colourSky60;
				height: 40px;
				border-radius: 4px;
				border: 1px solid $colourGrey80;
				transition-property: color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-backdrop-filter;
				transition-timing-function: cubic-bezier(.4,0,.2,1);
				transition-duration: .15s;
				&:hover {
					background-color: $colourSky20;
					border: 1px solid $colourSky60;
				}
				&:focus {
					background-color: white;
					border: 2px solid $colourSky60;
					margin: -1px;
				}
			}

			input:not(:placeholder-shown) ~ .floatingLabel,
			input:read-only ~ .floatingLabel,
			input:focus ~ .floatingLabel,
			textarea:focus ~ .floatingLabel,
			input:valid ~ .floatingLabel,
			textarea:valid ~ .floatingLabel{
				color: $colourGreyL1;
				font-size: 14px;
				top: -16px;
				left: 8px;
				opacity: 1;
			}

			.selectLabelShrink{
				position: relative;
				color: $colourGreyL1;
				//top: -16px;
				left: 8px;
				opacity: 1;
			}
			.floatingLabel {
				color: $colourGreyL3;
				pointer-events: none;
				position: absolute;
				left: 8px;
				top: 9px;

				@include ellipsis(calc(100% - 16px));
				@include transition(all 0.2s ease);
			}

			:not(textarea) {
				@include gradientUnderline;
			}

			&::after {
				opacity: 0;

				@include transition(opacity 0.3s ease-in-out);
			}

			&.focusWithin,
			&:hover {
				&::after {
					opacity: 1;
				}
			}

			&:not(.focusWithin) {
				.error{
					background-color: $colourRedDanger;
					border: 1px solid $colourRedError;
					color: $colourRedError;
					margin-bottom: -1px;
				}
			}

		}


		.passwordMessage {
			color: $colourRedError;
			font-size: 14px;
			margin: -16px 0 16px 0;

			@include transition(all 0.3s);
		}
	}

	.primaryImage {
		text-align: right;
		padding-right: 2%;

		img {
			display: inline-block;
			max-width: 300px;
		}
	}
}

.nominationReportPage {

	form, div.inviteForm {
		margin-top: 32px;

		&.narrow-form {
			width: 590px;
		}

		#{$all-text-inputs},
		select,
		textarea {
			color: $colourDefaultFont;
			display: block;
			font-family: $fontTelstraText;
			font-size: $fontDefaultSize;
			line-height: $fontTelstraTextLineHeight;
			outline: none;
			padding: 8px;
			width: 100%;

			border-radius: 4px;
			caret-color: $colourGreenL2;
			border: 1px solid $colourGreenL1;
			&:hover {
				background-color: $colourGreenL4;
				border: 1px solid $colourGreenL2;
			}
			&:focus {
				border: 2px solid $colourGreenL2;
			}

			@include placeholder {
				color: $colourGreyL3;
			}

		}

		.formRow {
			clear: both;
			margin-bottom: 24px;
			display: block;

			&:last-of-type {
				margin-bottom: 0;
			}

			p {
				margin: 16px 0 0 0;
			}

		}

		.formInput {
			margin-bottom: 20px;
			position: relative;
			clear: both;
			max-width: 900px;

			input {
				height: 40px;
			}

			input:not(:placeholder-shown) ~ .floatingLabel,
			input:read-only ~ .floatingLabel,
			input:focus ~ .floatingLabel,
			textarea:focus ~ .floatingLabel,
			input:valid ~ .floatingLabel,
			textarea:valid ~ .floatingLabel{
				color: $colourGreyL1;
				font-size: 14px;
				top: -16px;
				left: 8px;
				opacity: 1;
			}

			.selectLabelShrink{
				position: relative;
				color: $colourGreyL1;
				//top: -16px;
				left: 8px;
				opacity: 1;
			}

			&::after {
				opacity: 0;

				@include transition(opacity 0.3s ease-in-out);
			}

			&.focusWithin,
			&:hover {
				&::after {
					opacity: 1;
				}
			}

			&:not(.focusWithin) {
				.error{
					background-color: $colourRedDanger;
					border-bottom: 1px solid $colourRedError;
					color: $colourRedError;
					margin-bottom: -1px;
				}
			}

		}
	}

	.searchIcon {
		width: 18px;
		background: none;
		color: inherit;
		position: absolute;
		right: 7px;
		top: 10px;
		border: none;
		padding: 0;
		cursor: pointer;
		margin-top: 0;

		&:hover {
			color: $colourGreyL1;
			background-color: transparent;
		}

		span {
			background-color: transparent;
			padding: 0;
			font-size: 18px;
			color: $colourGreyL1;

			&:hover {
				color: $colourGreyL1;
				background-color: transparent;
			}
		}
	}
}

.fieldErrorMessage {
	color: $colourRedError;
	font-size: 14px;
	margin: -16px 0 16px 0;

	@include transition(all 0.3s);
}
.errorMessage {
	color: $colourRedError;
	font-size: 16px;

	@include transition(all 0.3s);
}

.MuiTooltip-popper{
	.MuiTooltip-tooltip {
		background-color: $colourGreyL1;
		font-size: 14px;
		.MuiTooltip-arrow {
			color: $colourGreyL1;
		}
	}
}