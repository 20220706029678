html {
	min-height: 100%;
}

body {
	color: $colourDefaultFont;
	font-family: $fontTelstraText;
	font-size: $fontDefaultSize;
	font-weight: normal;
	line-height: $fontTelstraTextLineHeight;
	margin: 0;
	padding-top: 80px;
	position: relative;
	min-height: 100vh;
	letter-spacing: -0.15px;

	&:after {
		display: none;
	}
}
h1,h2,h3,h4,h5 {
	font-weight: 400;
}

p {
	line-height: $fontTelstraTextLineHeight;
}

button {
	background: none;
	font-family: $fontTelstraText;
	font-weight: normal;
	outline: none;
}

img {
	display: block;
	max-width: 100%;
}

body > .wrapper{
	max-width: 100%;
}
a {
	color: $colourEarth60;
	text-decoration: none;
	&:visited {
		color: $colourEarth80;
	}
	&:hover {
		text-decoration: underline;
	}
}
.sky{
	a {
		color: $colourSky60;
		&:visited {
			color: $colourSky80;
		}
	}
}

.wrapper {
	@include clearfix;

	position: relative;
	margin: {
		top: 9px;
	}
	max-width: $max-width;
	min-height: -o-calc(100% - 80px); /* opera */
	min-height: -webkit-calc(100% - 80px); /* google, safari */
	min-height: -moz-calc(100% - 80px); /* firefox */
	&-pageHome {
		margin-top: 0;
		min-height: 0;

		a {
			color: $colourEarth60;
			text-decoration: none;
		}
	}
	background-color: white;
	display: flex;
	align-items: start;
	justify-content: center;
	width: 100%;

	.mainWrapper {
		transition: all 0.8s;
		width: 100%;
		background-color: white;
	}

	.main {
		width: 100%;
		max-width: 1184px;
		// float: left;
		margin: 0 auto;
		padding-top: 80px;
		padding-bottom: 80px;
	}

	@include mediaSmallScreen {
		.navigation {
			left: -100%;
			width: 100%;
			z-index: 9;
			display: none;
		}

		.mainWrapper {
			width: 100%;
		}

		.showMenu & {
			.navigation {
				left: 0;
			}
		}

		.main {
			padding: 30px;
		}
	}
}


ul:not(.MuiPagination-ul) {
	padding-inline-start: 18px;
	li::marker {
		font-size: 24px;
		position: relative;
		font-family: 'td-icons';
		font-style: normal;
		font-feature-settings: normal;
		font-variant: normal;
		font-weight: normal;
		line-height: 1;
		vertical-align: middle;
		speak: none;
		text-transform: none;
		-webkit-font-smoothing: antialiased;
		content: "\e6e2";
		color: $colourSky60;
	}
}

.sky, .magenta {
	ul:not(.MuiPagination-ul) {
		li::marker {
			color: $colourSky60;
		}
	}
}
.earth {
	ul:not(.MuiPagination-ul) {
		li::marker {
			color: $colourEarth60;
		}
	}
}
.green {
	ul:not(.MuiPagination-ul) {
		li::marker {
			color: $colourGreenL2;
		}
	}
}

.fontSky100 {
	color: #131A35;
}
.fontSky80 {
	color: #1A2E61;
}
.fontSky60 {
	color: #0D54FF;
}
.fontSky40 {
	color: #6CCBFE;
}
.fontEarth80 {
	color: #F44E1A;
}
.fontEarth60 {
	color: #F96449;
}
.fontWhite {
	color: #FFFFFF;
}