.pageSearchPartner {
  position: unset;
  width: 100% !important;

  .formSearchPartner {
    width: 400px;
    margin: 0;

    .formInput {
      margin-bottom: 0;
    }
  }

  .searchResultPopup {
    position: absolute;
    background-color: $colourGreyL5;
    border: 1px solid $colourGreyL2;
    border-top: 0;
    z-index: 1;

    height: 200px;
    width: 400px;

    ul {
      list-style: none;
      max-height: 200px;
      margin: 0;
      overflow: auto;
      padding: 0;
      text-indent: 8px;
    }

    li {
      line-height: 24px;

      &:hover {
        background-color: $colourGreyL3;
        cursor: pointer;
      }
    }
  }

  .searchIcon {
    width: 18px;
    background: none;
    color: inherit;
    position: absolute;
    right: 7px;
    top: 0;
    border: none;
    padding: 0;
    cursor: pointer;
    margin-top: 0;

    &:hover {
      color: $colourGreyL1;
      background-color: transparent;
    }

    span {
      background-color: transparent;
      padding: 0;
      font-size: 18px;
      color: $colourGreyL1;

      &:hover {
        color: $colourGreyL1;
        background-color: transparent;
      }
    }
  }

  .partnerDetailsContainer {
    clear: both;
    padding-top: 50px;
  }

  .field {
    @include clearfix;

    margin-bottom: 24px;

    &.last {
      margin-top: 32px;
    }

    h3 {
      margin-bottom: 10px;
    }
  }

  .table {
    width: 100%;
    float: left;
    border-bottom: 1px solid $colourGreyL1;;
  }

  .th, .tr {
    width: 100%;
    clear: both;
    min-height: 30px;
    float: left;

    .td {
      display: inline-block;
      width: 50%;
      float: left;
      padding-left: 8px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &_10 {
        width: 10%;
      }
      &_20 {
        width: 20%;
      }
      &_30 {
        width: 30%;
      }
      &_40 {
        width: 40%;
      }
      &_60 {
        width: 60%;
      }
      &_70 {
        width: 70%;
      }
      &_80 {
        width: 80%;
      }
      &_90 {
        width: 90%;
      }
    }
  }

  .th {
    .td {
      background-color: $colourGreenL2;
      color: white;
    }
  }

  //.tr {
  //  border-bottom: 1px solid $colourGreyL1;
  //}
}
