.fontBlack {
	color: $colourDefaultFont !important;
}

.fontGrey {
	color: #414141 !important;
}

.fontSmall {
	font-size: 85%;
}

.underline {
	text-decoration: underline;
}

.buttonAudioPlayer {
	background: transparent;
	border: none;
	color: $colourGreyL2;
	cursor: pointer;
	margin: 0;
	outline: none;
	padding: 0;

	&:hover {
		color: $colourGreyL1;
	}

	.pause {
		display: none;
	}

	.play {
		display: inline;
	}

	.contrast & {
		color: $colourWhite;

		&:hover {
			opacity: 0.8;
		}
	}

	&.playing {

		.pause {
			display: inline;
		}

		.play {
			 display: none;
		}
	}
}

.buttonDownload {
	background: transparent;
	border: none;
	color: $colourTurquoiseL2;
	cursor: pointer;
	font-size: 15px;
	padding: 0;
	margin: 0;
	text-decoration: none;
	vertical-align: bottom;

	> span {
		@include inline-block;
		vertical-align: middle;
	}

	&:hover {
		color: $colourTurquoiseL1;
	}
}

.buttonHelpful {
	background: transparent;
	border: none;
	cursor: pointer;
	margin: 0;
	outline: none;
	padding: 0;
	text-decoration: none;

	> i {
		background: url(#{$imagesDir}iconThumbsUpGreen.png) no-repeat;
		background-size: 100% 100%;
		@include inline-block;
		@include size(30);
		@include transition(background 0.3s);

		&:hover {
			background: url(#{$imagesDir}iconThumbsUpGreen_hover.png) no-repeat;
			background-size: 100% 100%;
		}
	}

	&.greyed {
		> i {
			background: url(#{$imagesDir}iconThumbsUpGrey.png) no-repeat;
			background-size: 100% 100%;
		}

		&:hover {
			> i {
				background: url(#{$imagesDir}iconThumbsUpGrey_hover.png) no-repeat;
				background-size: 100% 100%;
			}
		}
	}

	&.active {
		cursor: default;
	}
}

.buttonUnHelpful {
	background: transparent;
	border: none;
	cursor: pointer;
	margin: 0;
	outline: none;
	padding: 0;
	text-decoration: none;

	> i {
		background: url(#{$imagesDir}iconThumbsDownRed.png) no-repeat;
		background-size: 100% 100%;
		@include inline-block;
		@include size(30);
		@include transition(background 0.3s);

		&:hover {
			background: url(#{$imagesDir}iconThumbsDownRed_hover.png) no-repeat;
			background-size: 100% 100%;
		}
	}

	&.greyed {
		> i {
			background: url(#{$imagesDir}iconThumbsDownGrey.png) no-repeat;
			background-size: 100% 100%;
		}

		&:hover {
			> i {
				background: url(#{$imagesDir}iconThumbsDownGrey_hover.png) no-repeat;
				background-size: 100% 100%;
			}
		}
	}

	&.active {
		cursor: default;
	}
}

.buttonViewContainerContents {
	background: transparent;
	border: none;
	color: $colourGreyL2;
	cursor: pointer;
	font-size: 15px;
	float: right;
	@include inline-block;
	padding: 0;
	margin: 0;
	text-decoration: none;

	&:hover {
		color: $colourGreyL1;
	}

	> span {
		@include inline-block;
		vertical-align: middle;
	}
}

.buttonIconInfo {
	border: none;
	color: $colourGreyL2;
	cursor: pointer;
	float: right;
	margin: 8px 0 0 0;
	padding: 0;
	vertical-align: bottom;

	&:hover {
		color: $colourGreyL1;
	}

	&:active,
	&:focus {
		outline: none;
	}
}

.buttonTemplateInfo {
	border: none;
	color: $colourGreyL2;
	cursor: pointer;
	float: right;
	margin: 0;
	padding: 0;
	vertical-align: bottom;

	&:hover {
		color: $colourGreyL1;
	}

	&:active,
	&:focus {
		outline: none;
	}

	> i {
		border: 1px solid currentColor;
		border-radius: 50%;
		color: currentColor;
		display: inline-block;
		font-family: $fontTelstraText;
		font-style: normal;
		line-height: 1;
		padding: 5px 0 0 1px;
		position: relative;
		height: 24px;
		width: 24px;
	}
}

.buttonSearch {
	background: transparent;
	border: none;
	color: $colourGreyL2;
	cursor: pointer;
	margin: 0;
	padding: 0;

	&:hover {
		color: $colourGreyL1;
	}
}

.buttonClearSearch {
	background: transparent;
	border: none;
	color: $colourGreyL2;
	cursor: pointer;
	margin: 0;
	padding: 0;

	&:hover {
		color: $colourGreyL1;
	}
}

.buttonColour {
	background: transparent;
	border: 1px solid $colourWhite;
	border-radius: 50%;
	cursor: pointer;
	line-height: 6px;
	outline: none;
	padding: 3px;
	margin: 0;

	> i {
		border-radius: 50%;
		@include inline-block;
		@include size(14);
	}

	&.active {
		border-color: $colourGreyL1;
	}

	&.sky {
		> i {
			background: $colourSky60;
		}
	}
	&.earth {
		> i {
			background: $colourEarth60;
		}
	}
	&.blue {
		> i {
			background: $colourBlueL2;
		}
	}

	&.green {
		> i {
			background: $colourGreenL2;
		}
	}

	&.turquoise {
		> i {
			background: $colourTurquoiseL2;
		}
	}

	&.purple {
		> i {
			background: $colourPurpleL2;
		}
	}

	&.magenta {
		> i {
			background: $colourMagentaL2;
		}
	}

	&.orange {
		> i {
			background: $colourOrangeL2;
		}
	}

	&.grey {
		> i {
			background: $colourBlack;
		}

		.negative & {
			> i {
				background: lighten($colourBlack, 80);
			}
		}
	}

	&.all {
		border-radius: 15px;
		@include inline-block;
		line-height: 1em;
		margin-top: -4px;
		padding: 3px 10px 3px 10px;
		position: relative;
		vertical-align: middle;
	}
}

.buttonRegister {
	color: $colourEarth60;
	font-size: $fontDefaultSize;
	text-decoration: none;
	@include transition(color 0.3s);

	> span {
		@include inline-block;
		vertical-align: middle;
	}

	&:hover {
		color: $colourEarth80;

		> span {
			text-decoration: underline;
		}
	}
}

.buttonForgotPassword {
	color: $colourEarth60;
	font-size: $fontDefaultSize;
	text-decoration: none;
	@include transition(color 0.3s);

	> span {
		@include inline-block;
		vertical-align: middle;
	}

	&:hover {
		color: $colourEarth80;

		> span {
			text-decoration: underline;
		}
	}
}

.buttonPlay {
	background: $colourWhite;
	border: none;
	cursor: pointer;
	padding: 0;
}

.buttonMagentaWithArrow {
	@include button-with-arrow($colourSky60, $colourWhite, $colourSky80);
}

@mixin button-standard-icon-only($iconSrc) {
	background: transparent;
	border: none;
	cursor: pointer;
	line-height: 10px;
	padding: 0;
	margin: 0;
	text-decoration: none;
	@include inline-block();
	@include transition(opacity 0.3s);

	> i {
		background: {
			image: url($imagesDir + $iconSrc);
			repeat: no-repeat;
			size: 100%;
		}
		@include inline-block();
		@include size(25);
	}

	&:hover {
		opacity: 0.75;
	}
}

.buttonFacebook {
	@include button-standard-icon-only("iconFacebookGrey.png");
}

.buttonLinkedIn {
	@include button-standard-icon-only("iconLinkedInGrey.png");
}

.buttonTwitter {
	@include button-standard-icon-only("iconTwitterGrey.png");
}

@mixin icon-link($iconSrc) {
	background: url(#{$imagesDir}#{$iconSrc}) no-repeat;
	background-size: 100% 100%;
	@include inline-block;
	@include size(25);
	vertical-align: middle;

	+ span {
		@include inline-block;
		margin-left: 5px;
		vertical-align: middle;
	}
}

.buttonPaginationPrevious {
	span {
		@include inline-block;
		margin-left: 5px;
		vertical-align: middle;
	}
}

.buttonPaginationNext {
	span {
		@include inline-block;
		margin-right: 5px;
		vertical-align: middle;
	}
}

.element {
	.preview {
		> div {
			background: $downloadsGrey;
			height: 0;
			margin: {
				top: -6px;
				bottom: 8px;
			}
			padding-top: 66.45%;
			position: relative;

			> img {
				@include position(absolute, 50% 0 0 50%);
				@include transform(translateX(-50%) translateY(-50%));
			}

			img:not(.active) {
				display: none;
			}
		}

		> button {
			&:not(:first-of-type) {
				margin-left: 2px;
			}
		}

		+ .downloadOptions {
			margin-top: 16px;

			> button {
				&:not(:first-child) {
					margin-left: 6px;
				}

				&:hover {
					& span {
						text-decoration: underline;
					}
				}
			}
		}
	}

	&.negative {
		.preview {
			> div {
				background: $colourGreyL2;
			}
		}
	}
}

.container {
	.preview {
		background: $downloadsGrey;
		height: 0;
		margin-top: -6px;
		padding-top: percentage(470px / 780px);
		position: relative;

		> img {
			max-height: 90%;
			max-width: 80%;
			@include position(absolute, 50% 0 0 50%);
			@include transform(translateX(-50%) translateY(-50%));
		}

		+ .downloadOptions {
			margin-top: 16px;
			display: inline-block;
			width: 100%;
			@include clearfix();

			> a,
			> button {
				&:not(:first-child) {
					margin-left: 8px;
				}

				&:hover {
					& span {
						text-decoration: underline;
					}
				}
			}
		}
	}

	&.negative {
		.preview {
			background: $colourGreyL2;
		}
	}
}

.userBox {
	line-height: 10px;
	position: relative;
	@include inline-block();

	//> i {
	//	background: url(#{$imagesDir}iconUser.png) no-repeat;
	//	background-size: 100%;
	//	@include inline-block();
	//	@include size(25);
	//}

	> .userMenu {
		background: $colourWhite;
		border-radius: 8px;
		box-shadow: 0 0 4px rgba(0, 0, 0, 0.75);
		display: none;
		padding: 8px 0;
		@include position(absolute, 103% null null 50%);
		@include transform(translateX(-50%));
		z-index: 99;

		> a {
			color: $colourGreyL1;
			cursor: pointer;
			display: block;
			font-size: 13px;
			min-width: 150px;
			padding: 6px 12px;
			text-align: left;
			text-decoration: none;
			@include transition(all 0.15s);
			white-space: nowrap;

			&:hover {
				background: $colourGreyL5;
			}
		}
	}

	&:hover {
		> .userMenu {
			display: block;
		}
	}
}

.alert {
	color: $colourGreyL1;
	font-size: $fontSmallSize;
	margin: 24px 0;
	padding: 0;

	&:last-of-type {
		margin-bottom: 0;
	}

	a {
		color: $colourEarth60;
		text-decoration: none;

		&:hover {
			color: $colourEarth80;
			text-decoration: underline;
		}
	}

	&.alert-danger {
		background: $colourRedDanger;
		color: $colourRedError;
		padding: 8px;
		margin: 24px 0;

		a {
			color: $colourGreyL1;

			&:hover {
				color: $colourGreyL1;
			}
		}
	}
}

.linearGradient {
	height: 3px;
	width: 100%;

	&.blue {
		@include linear-gradient(90deg, #5BC0F2 0%, #C69DC9 35%, #8779B7 70%);
	}

	&.green {
		@include linear-gradient(90deg, #C6D86B 0%, #23B8D6 35%, #009792 70%);
	}

	&.magenta {
		@include linear-gradient(90deg, #C24279 0%, #EA5153 35%, #F7AB59 70%);
	}

	&.orange {
		@include linear-gradient(90deg, #F7AB59 0%, #EA5153 35%, #C24279 70%);
	}

	&.purple {
		@include linear-gradient(90deg, #8779B7 0%, #C69DC9 35%, #5BC0f2 70%);
	}

	&.turquoise {
		@include linear-gradient(90deg, #009792 0%, #23B8D6 35%, #C6D86b 70%);
	}
	&.sky {
		background-color: $colourSky60;
	}
	&.earth {
		background-color: $colourEarth60;
	}
}

.trainingClass {

	.imageAndVideoHolder {
		border: 1px solid $colourGreyL3;
		height: 0;
		overflow: hidden;
		padding-top: percentage(2853px / 5054px);
		position: relative;
		width: 100%;
		z-index: 5;

		> img,
		> .jwplayer,
		> .jw-error {
			height: 100% !important;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
		}

		> img {
			left: 50%;
			max-width: 10000%;
			top: 50%;
			@include transform(translateX(-50%) translateY(-50%));
			width: auto;
			z-index: 5;
		}

		> .jwplayer,
		> .jw-error {
			z-index: 8;
		}
	}

	.contentAndLinks {
		margin-top: 15px;
		@include clearfix();

		.content {
			float: left;
			line-height: $fontTelstraTextLineHeight;
			width: 50%;

			p {
				margin: 12px 0 0 0;

				&:first-child {
					margin-top: 0;
				}
			}
		}

		.links {
			float: right;
			padding-right: 30px;

			> a {
				color: $colourGreyL2;
				cursor: pointer;
				text-decoration: none;

				> span {
					font-size: 15px;
					@include inline-block;
					vertical-align: middle;
				}

				&:hover {
					color: $colourGreyL1;

					span {
						text-decoration: underline;
					}
				}
			}
		}
	}

}

.downloadTopContent {
	margin-bottom: 40px;

	a {
		color: $colourTurquoiseL2;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
}

.fieldStyledRadio {
	cursor: pointer;

	> input {
		display: none;

		+ i {
			border: 1px solid $colourGreyL2;
			border-radius: 50%;
			@include inline-block;
			line-height: 10px;
			padding: 3px;
			vertical-align: middle;

			> span {
				border-radius: 50%;
				@include inline-block;
				@include size(12px);
			}
		}

		&:checked {
			+ i {
				> span {
					background: $colourTurquoiseL2;
				}
			}
		}

		+ i + span {
			@include inline-block;
			margin-left: 3px;
			vertical-align: middle;
		}
	}
}

.formFilterSearch {
	@include clearfix();
	margin-bottom: 24px;

	> .filters {
		@include clearfix();
		float: left;
		margin-bottom: 24px;

		> label {
			margin-right: 7px;
		}
	}

	> .filters1 {
		@include clearfix();

		margin-bottom: 24px;
		float: left;
	}

	> .filters2 {
		@include clearfix();

		float: left;
		margin-bottom: 24px;
		width: 100%;

		> .sumoFilter {
			@include clearfix();

			float: left;
			margin-left: 8px;
			width: calc((100% - 6 * 9px) / 7);

			&:first-child {
				margin-left: 0;
			}

			> label {
				@include ellipsis;

				margin-bottom: 4px;
			}
		}
	}

	> .resultCount {
		@include clearfix();
		clear: both;
		float: left;
		margin: 0;

		> p {
			font-size: 18px;
			margin: 0;
		}
	}

	> .noticeEmploymentImages {
		font-size: 12px;
		max-width: calc(100% - 300px);
		float: right;
		margin-bottom: 24px;

		a {
			color: $colourTurquoiseL2;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}

.imageGridControls {
	min-height: 32px;
	margin-bottom: 24px;

	> .displayFilters {
		float: right;

		> div {
			display: inline-block;
			position: relative;

			&:after {
				color: $colourDefaultFont;
				content: "\e6e3";
				font-family: 'td-icons';
				padding: 0;
				pointer-events: none;
				position: absolute;
				right: 7px;
				top: 7px;
			}

			&:first-of-type {
				margin-right: 12px;
			}

			label {
				margin-right: 4px;
			}

			select {
				-ms-word-break: normal;
				word-break: normal;

				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				background: $colourWhite;
				color: $colourDefaultFont;
				border: 0;
				border-bottom: 1px solid $colourGreyL3;
				border-radius: 0;
				font: {
					family: $fontTelstraText;
					size: $fontDefaultSize;
				}
				line-height: $fontTelstraTextLineHeight;
				padding: 6px 30px 8px 8px;

				@include placeholder {
					color: $colourDefaultFont;
				}

				&:active,
				&:focus,
				&:hover {
					//border-color: $colourTurquoiseL3;
					//outline-color: $colourTurquoiseL3;
					box-shadow: 0 0 2px #7799D0;
					border-color: #7799D0;
				}
			}

			select::-ms-expand {
				display: none; /* remove default arrow on ie10 and ie11 */
			}
		}
	}
}

.pagination {
	list-style-type: none;
	margin: 0;
	padding: 2px 0 1px 0;

	li {
		@include inline-block;
		vertical-align: middle;

		> a {
			color: $colourTurquoiseL2;
			display: block;
			line-height: 15px;
			margin: 0 3px;
			padding: 8px 12px 6px 12px;
			text-decoration: none;
		}

		&.active,
		&:hover {
			> a {
				background: $colourTurquoiseL2;
				color: $colourWhite;
			}
		}

		&.disabled {
			display: none;
		}

		&:first-child,
		&:last-child {
			font-size: 22px;
			line-height: 15px;
		}
	}
}

.blockNews {
	position: relative;
	background: $downloadsGrey;
	height: 0;
	padding: {
		left: 20px;
		right: 20px;
	}

	> div {
		position: absolute;
		padding-top: 20px;
		overflow: hidden;
		height: 100%;

		a {
			display: inline;
		}
	}

	a {
		color: $colourSky40;
	}

	p {
		line-height: $fontTelstraTextLineHeight;
		margin-top: 0;
	}
}

.footerMain {
	.top {
		padding: 20px 0 0 0;

		&-wrapper {
			@include outer-container();

			> .lhs {
				float: left;
			}

			> .rhs {
				float: right;

				> a,
				> button {
					margin-left: 4px;

					&:first-child {
						margin-left: 0;
					}
				}
			}
		}
	}

	.bottom {
		padding: 20px 0;

		&-wrapper {
			@include outer-container();
		}
	}

	.containerLastEdited {
		margin-bottom: 10px;
	}

	.containerRelatedLinks {
		+ .containerGuidelinePagination {
			border-top: 1px solid $colourGreyL3;
			margin-top: 30px;
			padding-top: 30px;
		}
	}

	.containerPageFeedback {
		+ .containerPageFeedbackComment {
			margin-top: 10px;
		}
	}
}

.controls {
	bottom: percentage(144px / 2833px);
	left: percentage(148px / 5053px);
	border: 1px solid $colourGreyL3;
	position: absolute;
	z-index: 6;
	@include clearfix();

	> .buttonPlay {
		color: $colourGreyL2;
		float: left;
		height: 70px;
		width: 70px;

		&:hover {
			color: $colourGreyL1;
		}
	}

	> .meta {
		background: $colourWhite;
		float: right;
		height: 70px;
		padding: 13px 12px;

		> p {
			font-size: 13px;
			margin: 0 0 5px 0;
		}

		> h3 {
			margin: 0;
		}
	}
}

.pageLoader {
	width: 100%;
	min-height: 50vh;
	float: left;
	padding: 80px;
	padding-bottom: 0;
	text-align: center;
	clear: both;

	> img {
		display: block;
		margin: 0 auto 20px auto;
		max-width: 80px;
	}

	&.sky {
		color: $colourSky60;
	}
	&.earth {
		color: $colourEarth60;
	}

	&.green {
		color: $colourGreenL2;
	}

}

.baseLinks {
	background: $colourGreyL4;
	padding: 20px 30px;
	@include mediaAboveSmallScreen {
		padding-left: 224px;
	}
	z-index: -1;

	a {
		color: $colourGreyL1;
		font-size: 14px;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}

	&-wrapper {
		@include mediaAboveSmallScreen {
			margin-left: 80px;
		}
		min-height: 20px;
	}
}

.sitemap {
	padding-bottom: 30px;

	a {
		color: $colourSky60;
		text-decoration: none;

		&:hover {
			color: $colourSky80;
			text-decoration: underline;
		}
	}

	> .section {
		margin: 0 0 15px 0;

		.children {
			padding-left: 30px;
		}
	}
}

.homepageCarousel, #homeBannerDiv {
	width: 100%;
	height: 295px;
	position: relative;

	.slide {
		background: $colourSky80 center;
		background-size: cover;
		position: absolute;
		opacity: 0;
		transition: all 0.6s;
		width: 100%;
		height: 100%;

		a {
			display: block;
			width: 100%;
			height: 100%;
		}

		&.active {
			opacity: 1;
		}
	}

	.pagination {
		position: absolute;
		bottom: 0;
		text-align: center;
		width: 100%;
		height: 30px;

		.slideButton {
			cursor: pointer;
			display: inline-block;
			width: 20px;
			height: 30px;
			margin: 0 1px;

			.line {
				margin-top: 10px;

				&:hover {
					opacity: 0.7;
				}
			}

			&:not(.active) {
				.line {
					background: $colourWhite;
				}
			}
		}
	}
}

#homeBannerDiv{
	//background-image: url("images/Modernoffice.jpg");
	background-position: center 50%;
	background-size: cover;
	background-repeat: no-repeat;
}
