.adminReports {
  h1 {
    margin-bottom: 10px !important;
  }

  .field {
    margin-bottom: 25px;
  }

  .reportSection {
    padding: 30px 0;
    border-bottom: 1px solid $colourGreyL4;
  }

  .error {
    color: $colourRedError;
    font-size: 16px;
  }

  .downloadButton {
    background: $colourGreenL2;
    border: none;
    padding: 10px;
    color: white;
    width: 300px;
    cursor: pointer;

    span {
      float: left;
      line-height: 30px;
      font-size: 15px;
      margin-left: 10px;
    }

    i{
      font-size: 20px;
      color: white;
      float: right;
      line-height: 30px;
      top: -2px;
    }

    &:hover{
      background: $colourGreenL1;
    }

    &.clicked {
      background-color: $colourGreenL1;
      cursor: default;

      i {
        font-size: 24px;
        float: left;
        margin-left: 60px;
      }
      span {
        padding-left: 10px;
      }
    }
  }

  input {
    height: 40px;
    border: none;
    color: #414141;
    display: block;
    font-family: "TelstraText", Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 25px;
    outline: none;
    padding: 8px;
    width: 400px;
    border-bottom: 1px solid black;
  }

  .rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
    color: $colourGreenL2;
  }
}

.daterangepicker {
  td.active,
  td.active:hover {
    background-color: $colourGreenL2;
    border-color: transparent;
    color: white;
  }
  td.in-range {
    background-color: $pct50LightMagenta;
  }
  td.off.active.end-date.available,
  td.off.active.start-date.available {
    background-color: white;
  }

  td.active.end-date.available,
  td.active.start-date.available {
    background-color: $colourGreenL2;
    color: white;
  }

  td.available:hover{
    background-color: $colourGreenL4;
  }
}

.rs-picker-menu {
  .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    color: #ffffff;
    border-color: $colourGreenL2;
    background-color: $colourGreenL2;
    &:hover {
      color: #ffffff;
      border-color: $colourGreenL2;
      background-color: $colourGreenL2;
    }
  }
  .rs-calendar-table-cell-content:hover {
    background-color: $colourGreenL4;
    border-color: transparent;
  }
  .rs-calendar-table-cell-in-range::before{
    background-color: $pct50LightGreen;
  }
  .rs-calendar-table-cell-is-today .rs-calendar-table-cell-content{
    border: 1px solid $colourGreenL2;
    padding: 4px;
  }
  .rs-picker-toolbar-right {
    .rs-picker-toolbar-right-btn-ok {
      background-color: $colourGreenL2;
      &:not(.rs-btn-disabled):hover {
        background-color: $colourGreenL1;
      }
    }
  }
  .rs-picker-toolbar-ranges {
    .rs-picker-toolbar-option {
      color: $colourGreenL2;
    }
  }

}

