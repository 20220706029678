@font-face {
	font-family: 'td-icons';
	src: url('../assets/fonts/td-icons/td-original-icons.eot');
	src: url('../assets/fonts/td-icons/td-original-icons.eot#iefix') format('embedded-opentype'),
		url('../assets/fonts/td-icons/td-original-icons.woff') format('woff'),
		url('../assets/fonts/td-icons/td-original-icons.ttf') format('truetype'),
		url('../assets/fonts/td-icons/td-original-icons.svg#td-original-icons') format('svg');
	font-weight: normal;
	font-style: normal;
}

.td-icon,
.td-icon-xs,
.td-icon-s,
.td-icon-sm,
.td-icon-md,
.td-icon-lg,
.td-icon-xl {
	color: inherit;
	position: relative;
	font-family: 'td-icons';
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	line-height: 1;
	vertical-align: middle;
	speak: none;
	text-transform: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}


/* icon sizes */

.td-icon {
	font-size: 14px;
	/*font-size: 1.6rem;*/
}

.td-icon-xs {
	font-size: 16px;
	/*font-size: 1.6rem;*/
}

.td-icon-s {
	font-size: 24px;
	/*font-size: 1.6rem;*/
}

.td-icon-sm {
	font-size: 42px;
	/*font-size: 4.2rem;*/
}

.td-icon-md {
	font-size: 70px;
	/*font-size: 7rem;*/
}

.td-icon-lg {
	font-size: 118px;
	/*font-size: 11.8rem;*/
}

.td-icon-xl {
	font-size: 168px;
	/*font-size: 16.8rem;*/
}


/* colours & hovers */

.td-icon:hover {
	color: inherit;
}

/*
.orange.td-icon,
.orange .td-icon {
	color: #D93923;
}

.orange.td-icon:hover,
.orange .td-icon:hover {
	color: #9E043E;
}

.purple.td-icon,
.purple .td-icon {
	color: #A0148C;
}

.purple.td-icon:hover,
.purple .td-icon:hover {
	color: #570066;
}

.turquoise.td-icon,
.turquoise .td-icon {
	color: #008381;
}

.turquoise.td-icon:hover,
.turquoise .td-icon:hover {
	color: #025450;
}

.green.td-icon,
.green .td-icon {
	color: #008A00;
}

.green.td-icon:hover,
.green .td-icon:hover {
	color: #074F30;
}

.magenta.td-icon,
.magenta .td-icon {
	color: #A0148C;
}

.magenta.td-icon:hover,
.magenta .td-icon:hover {
	color: #570066;
}

.blue.td-icon,
.blue .td-icon {
	color: #0064D2;
}
.blue.td-icon:hover,
.blue .td-icon:hover {
	color: #001E82;
}

.grey.td-icon,
.grey .td-icon {
	color: #787878;
}

.grey.td-icon:hover,
.grey .td-icon:hover {
	color: #414141;
}*/


/*.td-icon-hover {*/
	/*text-decoration: none;*/
/*}*/

.td-icon-hover:hover {
	text-decoration: none;
	color: #fff !important;
	background-color: #787878;
}
/*
.orange .td-icon-hover:hover {
	background-color: #ec5600;
}

.purple .td-icon-hover:hover {
	background-color: #7c69b8;
}

.turquoise .td-icon-hover:hover {
	background-color: #29a4a2;
}

.green .td-icon-hover:hover {
	background-color: #00cc33;
}

.magenta .td-icon-hover:hover {
	background-color: #e8398d;
}

.blue .td-icon-hover:hover {
	/*background-color: #00b4ff;
}*/

/*.default .td-icon-hover:hover {
	background-color: #00b4ff;
}*/


/* circle states & hover colours */

.td-icon-circle {
	padding: 4px !important;
	border-radius: 50%;
	border: 1px solid #787878;
}

.td-icon-xs.td-icon-circle {
	border-width: 1px;
}

.td-icon-s.td-icon-circle {
	border-width: 2px;
}

.td-icon-sm.td-icon-circle {
	border-width: 3px;
}

.td-icon-md.td-icon-circle {
	border-width: 4px;
}

.td-icon-lg.td-icon-circle {
	border-width: 7px;
}

.td-icon-xl.td-icon-circle {
	border-width: 9px;
}

.td-icon-circle:hover {
	border-color: #414141;
}


.coloured.td-icon-circle {
	border-color: inherit;
}

.coloured.td-icon-circle:hover {
	border-color: inherit;
}


/*.orange .coloured.td-icon-circle {
	border-color: #D93923;
}

.orange .coloured.td-icon-circle:hover {
	border-color: #9E043E;
}

.purple .coloured.td-icon-circle {
	border-color: #5E50BF;
}

.purple .coloured.td-icon-circle:hover {
	border-color: #35237F;
}

.turquoise .coloured.td-icon-circle {
	border-color: #008381;
}

.turquoise .coloured.td-icon-circle:hover {
	border-color: #025450;
}

.green .coloured.td-icon-circle {
	border-color: #008A00;
}

.green .coloured.td-icon-circle:hover {
	border-color: #074F30;
}

.magenta .coloured.td-icon-circle {
	border-color: #A0148C;
}

.magenta .coloured.td-icon-circle:hover {
	border-color: #570066;
}

.blue .coloured.td-icon-circle {
	border-color: #0064D2;
}

.blue .coloured.td-icon-circle:hover {
	border-color: #001E82;
}*/

/*.default .td-icon-circle:hover {
	border-color: #1964c8;
}*/


/**
	Specific offset hacks
*/
.icon-download {
	top: -2px;
}


.icon-current-contract-expiring:before {
	content: "\e96e";
}

.icon-legacy-product-expiring:before {
	content: "\e96f";
}

.icon-market-comparison-recommendation:before {
	content: "\e970";
}

.icon-product-upgrade:before {
	content: "\e971";
}

.icon-bookmark:before {
	content: "\e95f";
}

.icon-business-ip:before {
	content: "\e960";
}

.icon-connect-ip:before {
	content: "\e961";
}

.icon-ethernet-man:before {
	content: "\e962";
}

.icon-internet-direct:before {
	content: "\e963";
}

.icon-man:before {
	content: "\e964";
}

.icon-managed-solution:before {
	content: "\e965";
}

.icon-notification:before {
	content: "\e966";
}

.icon-optic-wave:before {
	content: "\e967";
}

.icon-share:before {
	content: "\e968";
}

.icon-unfollow:before {
	content: "\e969";
}

.icon-upgrade:before {
	content: "\e96a";
}

.icon-vd:before {
	content: "\e96b";
}

.icon-vpn:before {
	content: "\e96c";
}

.icon-wan:before {
	content: "\e96d";
}

.icon-speech:before {
	content: "\e95e";
}

.icon-alert-solid-round:before {
	content: "\e95c";
}

.icon-alert-solid-triangle:before {
	content: "\e95d";
}

.icon-troubleshooting:before {
	content: "\e95b";
}

.icon-telstra-vantage:before {
	content: "\e957";
}

.icon-information-solid:before {
	content: "\e956";
}

.icon-crowd-support-filled:before {
	content: "\e955";
}

.icon-marketing-choices:before {
	content: "\e954";
}

.icon-law-enforcement:before {
	content: "\e953";
}

.icon-advertising:before {
	content: "\e94d";
}

.icon-aggregating-info:before {
	content: "\e94e";
}

.icon-cookies:before {
	content: "\e94f";
}

.icon-encryption:before {
	content: "\e950";
}

.icon-keep-information:before {
	content: "\e951";
}

.icon-lan-enforcement:before {
	content: "\e952";
}

.icon-marketing:before {
	content: "\e958";
}

.icon-online-safety:before {
	content: "\e959";
}

.icon-research-analytics:before {
	content: "\e95a";
}

.icon-help-support:before {
	content: "\e723";
}

.icon-contactus:before {
	content: "\e722";
}

.icon-gst:before {
	content: "\e934";
}

.icon-promo:before {
	content: "\e94c";
}

.icon-14-days-free-poc:before {
	content: "\e918";
}

.icon-14-days-free-trial:before {
	content: "\e919";
}

.icon-30-days-free-poc:before {
	content: "\e91a";
}

.icon-30-days-free-trial:before {
	content: "\e91b";
}

.icon-access-permissions:before {
	content: "\e91c";
}

.icon-ato-compliant:before {
	content: "\e91d";
}

.icon-barcode-scanning:before {
	content: "\e91e";
}

.icon-blog:before {
	content: "\e91f";
}

.icon-branding:before {
	content: "\e920";
}

.icon-business-apps:before {
	content: "\e921";
}

.icon-collect-mobile-payments:before {
	content: "\e922";
}

.icon-concierge-service:before {
	content: "\e923";
}

.icon-consultancy-design-service:before {
	content: "\e924";
}

.icon-convert-paper-to-digital:before {
	content: "\e925";
}

.icon-crm2:before {
	content: "\e926";
}

.icon-customer-testimonials:before {
	content: "\e927";
}

.icon-design:before {
	content: "\e928";
}

.icon-desktop-mob-tab-access:before {
	content: "\e929";
}

.icon-dispatch-workflow:before {
	content: "\e92a";
}

.icon-document-tracking:before {
	content: "\e92b";
}

.icon-drag-and-drop-form:before {
	content: "\e92c";
}

.icon-electronic-signatures:before {
	content: "\e92d";
}

.icon-email-support:before {
	content: "\e92e";
}

.icon-file-sharing:before {
	content: "\e92f";
}

.icon-free-demo:before {
	content: "\e930";
}

.icon-gps-mobile-tracking:before {
	content: "\e931";
}

.icon-gps-tablet-tracking:before {
	content: "\e932";
}

.icon-gps-vehicle-tracking:before {
	content: "\e933";
}

.icon-human-verified:before {
	content: "\e935";
}

.icon-inbuilt-integration:before {
	content: "\e936";
}

.icon-magic-envelopes:before {
	content: "\e937";
}

.icon-maps-directions:before {
	content: "\e938";
}

.icon-non-profit:before {
	content: "\e939";
}

.icon-offline-access:before {
	content: "\e93a";
}

.icon-paying-invoice:before {
	content: "\e93b";
}

.icon-payroll:before {
	content: "\e93c";
}

.icon-profitable:before {
	content: "\e93d";
}

.icon-receipt-management:before {
	content: "\e93e";
}

.icon-recognition:before {
	content: "\e93f";
}

.icon-sale:before {
	content: "\e940";
}

.icon-science:before {
	content: "\e941";
}

.icon-secure-storage:before {
	content: "\e942";
}

.icon-single-sign-on:before {
	content: "\e943";
}

.icon-staff-rostering:before {
	content: "\e944";
}

.icon-technologies2:before {
	content: "\e945";
}

.icon-telstra-apps-marketplace:before {
	content: "\e946";
}

.icon-time-entries-approval:before {
	content: "\e947";
}

.icon-timesheets:before {
	content: "\e948";
}

.icon-tracking-time:before {
	content: "\e949";
}

.icon-unlimited-users:before {
	content: "\e94a";
}

.icon-webinar:before {
	content: "\e94b";
}

.icon-number-eight-invert:before {
	content: "\e90e";
}

.icon-number-five-invert:before {
	content: "\e90f";
}

.icon-number-four-invert:before {
	content: "\e910";
}

.icon-number-nine-invert:before {
	content: "\e911";
}

.icon-number-one-invert:before {
	content: "\e912";
}

.icon-number-seven-invert:before {
	content: "\e913";
}

.icon-number-six-invert:before {
	content: "\e914";
}

.icon-number-ten-invert:before {
	content: "\e915";
}

.icon-number-three-invert:before {
	content: "\e916";
}

.icon-number-two-invert:before {
	content: "\e917";
}

.icon-iot:before {
	content: "\e90d";
}

.icon-smart-home:before {
	content: "\e90c";
}

.icon-calling-pack:before {
	content: "\e90a";
}

.icon-foxtel:before {
	content: "\e90b";
}

.icon-linkedin:before {
	content: "\e909";
}

.icon-google-plus:before {
	content: "\e908";
}

.icon-round-tick:before {
	content: "\e906";
}

.icon-round-cross:before {
	content: "\e907";
}

.icon-arts:before {
	content: "\e905";
}

.icon-id:before {
	content: "\e904";
}

.icon-hashtag:before {
	content: "\e903";
}

.icon-message:before {
	content: "\e902";
}

.icon-speed-guide:before {
	content: "\e900";
}

.icon-location:before {
	content: "\e901";
}

.icon-telstra-tv:before {
	content: "\e721";
}

.icon-telstra-air:before {
	content: "\e71f";
}

.icon-wayfinding:before {
	content: "\e720";
}

.icon-ui-largearrow-down:before {
	content: "\e71e";
}

.icon-ui-video-play:before {
	content: "\e71d";
}

.icon-credit-meu:before {
	content: "\e719";
}

.icon-mobile-plan:before {
	content: "\e71a";
}

.icon-outside-link-arrow:before {
	content: "\e71b";
}

.icon-security-shield:before {
	content: "\e71c";
}

.icon-leave-it-with-us:before {
	content: "\e70f";
}

.icon-works-on-nbn:before {
	content: "\e716";
}

.icon-alert-round:before {
	content: "\e714";
}

.icon-alert-triangle:before {
	content: "\e715";
}

.thumbs-down:before {
	content: "\e710";
}

.thumbs-up:before {
	content: "\e711";
}

.icon-collaboration:before {
	content: "\e706";
}

.icon-customer-contact-solution:before {
	content: "\e707";
}

.icon-digital-media:before {
	content: "\e708";
}

.icon-education:before {
	content: "\e709";
}

.icon-financial-services:before {
	content: "\e70a";
}

.icon-machine-to-machine:before {
	content: "\e70b";
}

.icon-mining-resources:before {
	content: "\e70c";
}

.icon-self-service-apps:before {
	content: "\e70d";
}

.icon-transport-logistics:before {
	content: "\e70e";
}

.icon-aged-care:before {
	content: "\e700";
}

.icon-general-practitioner:before {
	content: "\e701";
}

.icon-hospital:before {
	content: "\e702";
}

.icon-pharmacy:before {
	content: "\e703";
}

.icon-telstra-heroes:before {
	content: "\e704";
}

.icon-computer-protection:before {
	content: "\e6f9";
}

.icon-safe-browser:before {
	content: "\e6fa";
}

.icon-new-phone-feeling:before {
	content: "\e6fb";
}

.icon-new-phone-feeling-free:before {
	content: "\e6fc";
}

.icon-parental-control:before {
	content: "\e6fd";
}

.icon-4g-double-coverage:before {
	content: "\e601";
}

.icon-4g-network-speed:before {
	content: "\e602";
}

.icon-add-ons:before {
	content: "\e603";
}

.icon-alerts-reminders:before {
	content: "\e604";
}

.icon-anti-spam:before {
	content: "\e605";
}

.icon-anti-virus:before {
	content: "\e606";
}

.icon-application-ngdr:before {
	content: "\e607";
}

.icon-application-site-minder:before {
	content: "\e608";
}

.icon-application-uc:before {
	content: "\e609";
}

.icon-application-vistabridge:before {
	content: "\e60a";
}

.icon-application-vistacockpit:before {
	content: "\e60b";
}

.icon-application-vistamart:before {
	content: "\e60c";
}

.icon-apps-24x7:before {
	content: "\e60d";
}

.icon-audio-web:before {
	content: "\e60e";
}

.icon-backup:before {
	content: "\e60f";
}

.icon-backup-safe:before {
	content: "\e610";
}

.icon-bill-date:before {
	content: "\e611";
}

.icon-bill-edit-details:before {
	content: "\e612";
}

.icon-billing:before {
	content: "\e613";
}

.icon-bill-manage:before {
	content: "\e614";
}

.icon-bill-paper:before {
	content: "\e615";
}

.icon-bill-paperless:before {
	content: "\e616";
}

.icon-bill-payment:before {
	content: "\e617";
}

.icon-bill-payment-extension:before {
	content: "\e618";
}

.icon-bluetooth:before {
	content: "\e619";
}

.icon-bundle:before {
	content: "\e61a";
}

.icon-business-centers:before {
	content: "\e61b";
}

.icon-business-resources:before {
	content: "\e61c";
}

.icon-business-building:before {
	content: "\e61d";
}

.icon-business-secure:before {
	content: "\e61e";
}

.icon-business-small-building:before {
	content: "\e61f";
}

.icon-calendar:before {
	content: "\e620";
}

.icon-camera:before {
	content: "\e621";
}

.icon-case-studies:before {
	content: "\e622";
}

.icon-chat-forums:before {
	content: "\e623";
}

.icon-check-usage:before {
	content: "\e624";
}

.icon-cloud:before {
	content: "\e625";
}

.icon-cloud-payg:before {
	content: "\e626";
}

.icon-cloud-tipt-phone:before {
	content: "\e627";
}

.icon-cloud-video-conferencing:before {
	content: "\e628";
}

.icon-computer-cpu:before {
	content: "\e629";
}

.icon-coverage-maps:before {
	content: "\e62a";
}

.icon-crm:before {
	content: "\e62b";
}

.icon-crowd-support:before {
	content: "\e62c";
}

.icon-customer-relationship-management:before {
	content: "\e62d";
}

.icon-data-calculator:before {
	content: "\e62e";
}

.icon-desk:before {
	content: "\e62f";
}

.icon-machine-to-machine2:before {
	content: "\e712";
}

.icon-device-support:before {
	content: "\e630";
}

.icon-direct-debit:before {
	content: "\e631";
}

.icon-document-service-improve-reporting:before {
	content: "\e632";
}

.icon-document-zip:before {
	content: "\e633";
}

.icon-dot:before {
	content: "\e634";
}

.icon-download:before {
	content: "\e635";
}

.icon-international-roaming-data-pack:before {
	content: "\e636";
}

.icon-data-plus-pack:before {
	content: "\e637";
}

.icon-download-exe:before {
	content: "\e638";
}

.icon-download-jpg:before {
	content: "\e639";
}

.icon-download-pdf:before {
	content: "\e63a";
}

.icon-download-png:before {
	content: "\e63b";
}

.icon-email:before {
	content: "\e63c";
}

.icon-email-security:before {
	content: "\e63d";
}

.icon-find-telstra-store:before {
	content: "\e63e";
}

.icon-firewall:before {
	content: "\e63f";
}

.icon-flash-usb:before {
	content: "\e640";
}

.icon-flybuys:before {
	content: "\e641";
}

.icon-forgotten-password:before {
	content: "\e642";
}

.icon-forgotten-username:before {
	content: "\e643";
}

.icon-free-banner:before {
	content: "\e645";
}

.icon-free-delivery:before {
	content: "\e646";
}

.icon-games:before {
	content: "\e647";
}

.icon-government:before {
	content: "\e648";
}

.icon-greater-reliability:before {
	content: "\e649";
}

.icon-health-care:before {
	content: "\e64a";
}

.icon-help:before {
	content: "\e64b";
}

.icon-home:before {
	content: "\e64c";
}

.icon-hosted-cloud-services:before {
	content: "\e64d";
}

.icon-industries:before {
	content: "\e64e";
}

.icon-information:before {
	content: "\e64f";
}

.icon-installation:before {
	content: "\e650";
}

.icon-installation-platinum:before {
	content: "\e651";
}

.icon-interactive-voice-response:before {
	content: "\e652";
}

.icon-interface-me:before {
	content: "\e653";
}

.icon-international-roaming:before {
	content: "\e654";
}

.icon-domain-name:before {
	content: "\e655";
}

.icon-online-portal:before {
	content: "\e656";
}

.icon-internet:before {
	content: "\e657";
}

.icon-internet-security:before {
	content: "\e658";
}

.icon-internet-support:before {
	content: "\e659";
}

.icon-upgrade-dial-up:before {
	content: "\e65a";
}

.icon-ip-address:before {
	content: "\e65b";
}

.icon-lan-switch:before {
	content: "\e65c";
}

.icon-laptop:before {
	content: "\e65d";
}

.icon-laptop-phone:before {
	content: "\e65e";
}

.icon-live-chat-24x7:before {
	content: "\e65f";
}

.icon-live-help:before {
	content: "\e660";
}

.icon-load-balancer:before {
	content: "\e661";
}

.icon-lock:before {
	content: "\e662";
}

.icon-mail:before {
	content: "\e663";
}

.icon-managed-phone:before {
	content: "\e664";
}

.icon-managed-video-conferencing:before {
	content: "\e665";
}

.icon-manage-services:before {
	content: "\e666";
}

.icon-mms:before {
	content: "\e667";
}

.icon-mobile:before {
	content: "\e668";
}

.icon-prepaid-mobile-broadband:before {
	content: "\e669";
}

.icon-mobile-broadband:before {
	content: "\e66a";
}

.icon-mobile-byo:before {
	content: "\e66b";
}

.icon-mobile-car-kit:before {
	content: "\e66c";
}

.icon-mobile-data-pack:before {
	content: "\e66d";
}

.icon-mobile-foxtel:before {
	content: "\e66e";
}

.icon-mobile-insurance:before {
	content: "\e66f";
}

.icon-mobile-mentor:before {
	content: "\e670";
}

.icon-mobile-modem:before {
	content: "\e671";
}

.icon-mobile-no-lock-in:before {
	content: "\e672";
}

.icon-mobile-on-plan:before {
	content: "\e673";
}

.icon-mobile-prepaid:before {
	content: "\e674";
}

.icon-modem:before {
	content: "\e675";
}

.icon-movies:before {
	content: "\e676";
}

.icon-movies-hd:before {
	content: "\e677";
}

.icon-multi-lay-switch:before {
	content: "\e678";
}

.icon-music:before {
	content: "\e679";
}

.icon-my-details:before {
	content: "\e67a";
}

.icon-my-profile:before {
	content: "\e67b";
}

.icon-my-profile-tick-presence:before {
	content: "\e67c";
}

.icon-network-australia-wide:before {
	content: "\e67d";
}

.icon-network-coverage:before {
	content: "\e67e";
}

.icon-network-elem-managers:before {
	content: "\e67f";
}

.icon-network-email:before {
	content: "\e680";
}

.icon-network-speed:before {
	content: "\e681";
}

.icon-news:before {
	content: "\e682";
}

.icon-no-excess:before {
	content: "\e683";
}

.icon-note:before {
	content: "\e684";
}

.icon-operating-system:before {
	content: "\e685";
}

.icon-operations-centre:before {
	content: "\e686";
}

.icon-overseas-branch:before {
	content: "\e687";
}

.icon-pay-online:before {
	content: "\e688";
}

.icon-phone:before {
	content: "\e689";
}

.icon-phone-add-features:before {
	content: "\e68a";
}

.icon-phone-change-plans:before {
	content: "\e68b";
}

.icon-phone-hd-voice:before {
	content: "\e68c";
}

.icon-phone-ip:before {
	content: "\e68d";
}

.icon-phone-office:before {
	content: "\e68e";
}

.icon-phone-tipt:before {
	content: "\e68f";
}

.icon-prepaid-activation:before {
	content: "\e690";
}

.icon-prepaid-register:before {
	content: "\e691";
}

.icon-price-promise:before {
	content: "\e692";
}

.icon-print:before {
	content: "\e693";
}

.icon-profile-suit:before {
	content: "\e694";
}

.icon-public-safety-security:before {
	content: "\e695";
}

.icon-puk-code:before {
	content: "\e696";
}

.icon-recharge:before {
	content: "\e697";
}

.icon-register:before {
	content: "\e698";
}

.icon-research-insights:before {
	content: "\e699";
}

.icon-router:before {
	content: "\e69a";
}

.icon-search-find:before {
	content: "\e69b";
}

.icon-secure-delivery:before {
	content: "\e69c";
}

.icon-server:before {
	content: "\e69d";
}

.icon-service-desk:before {
	content: "\e69e";
}

.icon-service-status:before {
	content: "\e69f";
}

.icon-settings:before {
	content: "\e6a0";
}

.icon-shared-wifi:before {
	content: "\e6a1";
}

.icon-shop-online:before {
	content: "\e6a2";
}

.icon-shop-track-order:before {
	content: "\e6a3";
}

.icon-sim-card:before {
	content: "\e6a4";
}

.icon-sim-card-no-lock:before {
	content: "\e6a5";
}

.icon-sip-connect:before {
	content: "\e6a6";
}

.icon-small-business:before {
	content: "\e6a7";
}

.icon-sms:before {
	content: "\e6a8";
}

.icon-software-apps:before {
	content: "\e6a9";
}

.icon-special-offer:before {
	content: "\e6aa";
}

.icon-sports:before {
	content: "\e6ab";
}

.icon-star:before {
	content: "\e6ac";
}

.icon-storage:before {
	content: "\e6ad";
}

.icon-support:before {
	content: "\e6ae";
}

.icon-tablet:before {
	content: "\e6af";
}

.icon-target:before {
	content: "\e6b0";
}

.icon-tv:before {
	content: "\e6b1";
}

.icon-tv-clear:before {
	content: "\e713";
}

.icon-tbox:before {
	content: "\e6b2";
}

.icon-telepres:before {
	content: "\e6b3";
}

.icon-technologies:before {
	content: "\e6b4";
}

.icon-teg-portal:before {
	content: "\e6b5";
}

.icon-telstra-firewall:before {
	content: "\e6b6";
}

.icon-telstra-lan-switch:before {
	content: "\e6b7";
}

.icon-telstra-plus:before {
	content: "\e6b8";
}

.icon-telstra-plus-home-support:before {
	content: "\e6b9";
}

.icon-thub2:before {
	content: "\e6ba";
}

.icon-tick-square:before {
	content: "\e6bb";
}

.icon-thick-tick:before {
	content: "\e6f7";
}

.icon-tick:before {
	content: "\e6f8";
}

.icon-time:before {
	content: "\e6bc";
}

.icon-transfer-data:before {
	content: "\e6bd";
}

.icon-transfer-plan:before {
	content: "\e6be";
}

.icon-twitter:before {
	content: "\e6bf";
}

.icon-unified-comms:before {
	content: "\e6c0";
}

.icon-unlock:before {
	content: "\e6c1";
}

.icon-volume-off:before {
	content: "\e6c2";
}

.icon-volume-on:before {
	content: "\e6c3";
}

.icon-wan-accelerate:before {
	content: "\e6c4";
}

.icon-warranty-2yr:before {
	content: "\e6c5";
}

.icon-weather:before {
	content: "\e6c6";
}

.icon-wireless-access-point:before {
	content: "\e6c7";
}

.icon-telstra-router:before {
	content: "\e6c8";
}

.icon-sess-border-control:before {
	content: "\e6c9";
}

.icon-wireless-router:before {
	content: "\e6ca";
}

.icon-facebook:before {
	content: "\e6cb";
}

.icon-twitter-bird:before {
	content: "\e6cc";
}

.icon-youtube:before {
	content: "\e6cd";
}

.icon-telstra:before {
	content: "\e6ce";
}

.icon-bigpond:before {
	content: "\e6cf";
}

.icon-inventory-system:before {
	content: "\e6d0";
}

.icon-research-evaluation:before {
	content: "\e6d1";
}

.icon-sass:before {
	content: "\e6d2";
}

.icon-teg:before {
	content: "\e6d3";
}

.icon-infra-service-iaas:before {
	content: "\e6d4";
}

.icon-number-one:before {
	content: "\e600";
}

.icon-number-two:before {
	content: "\e6d5";
}

.icon-number-three:before {
	content: "\e6d6";
}

.icon-number-four:before {
	content: "\e6d7";
}

.icon-number-five:before {
	content: "\e6d8";
}

.icon-number-six:before {
	content: "\e6d9";
}

.icon-number-seven:before {
	content: "\e6da";
}

.icon-number-eight:before {
	content: "\e6db";
}

.icon-number-nine:before {
	content: "\e6dc";
}

.icon-number-ten:before {
	content: "\e6dd";
}

.icon-ui-left-arrow-round:before {
	content: "\e6fe";
}

.icon-ui-right-arrow-round:before {
	content: "\e6ff";
}

.icon-ui-slider-button:before {
	content: "\e705";
}

.icon-ui-cross:before {
	content: "\e6de";
}

.icon-ui-plus:before {
	content: "\e6df";
}

.icon-ui-minus:before {
	content: "\e6e0";
}

.icon-ui-left-arrow-thick:before {
	content: "\e6e1";
}

.icon-ui-right-arrow-thick:before {
	content: "\e6e2";
}

.icon-ui-down-arrow-thick:before {
	content: "\e6e3";
}

.icon-ui-up-arrow-thick:before {
	content: "\e6e4";
}

.icon-ui-left-arrow:before {
	content: "\e6f3";
}

.icon-ui-right-arrow:before {
	content: "\e6f4";
}

.icon-ui-down-arrow:before {
	content: "\e6f5";
}

.icon-ui-up-arrow:before {
	content: "\e6f6";
}

.icon-ui-play:before {
	content: "\e6e5";
}

.icon-ui-pause:before {
	content: "\e6e6";
}

.icon-ui-stop:before {
	content: "\e6e7";
}

.icon-ui-replay:before {
	content: "\e6e8";
}

.icon-ui-start:before {
	content: "\e6e9";
}

.icon-ui-end:before {
	content: "\e6ea";
}

.icon-ui-rewind:before {
	content: "\e6eb";
}

.icon-ui-fast-forward:before {
	content: "\e6ec";
}

.icon-ui-left-arrow-button:before {
	content: "\e6ed";
}

.icon-ui-right-arrow-button:before {
	content: "\e6ee";
}

.icon-ui-down-arrow-button:before {
	content: "\e6ef";
}

.icon-ui-up-arrow-button:before {
	content: "\e6f0";
}

.icon-ui-play-button:before {
	content: "\e6f1";
}

.icon-ui-search:before {
	content: "\e6f2";
}

.icon-retail-store:before {
	content: "\e717";
}

.icon-flagship-store:before {
	content: "\e718";
}