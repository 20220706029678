@import "containerLogos";
@import "header";
@import "imageGenerator";
@import "awardBadgeGenerator";
@import "badgeGenerator";
@import "navigation";
@import "partnerSearch";
@import "adminReports";

.layout-page {
	background: $colourWhite;
}
.layout-homePage {
	.wrapper {
		background-color: white;
		.mainWrapper {
			background-color: white;
		}
	}
}

.layout-login,
.layout-logout,
.layout-register,
.layout-registerPending,
.layout-registerSuccess,
.layout-403,
.layout-404{
	background: {
		attachment: fixed;
		//image: url($imagesDir + 'Modernoffice.jpg');
		repeat: no-repeat;
		size: cover;
		position: center;
		color: $colourSand20
	}
}



.containerPrimaryImageAndMeta {
	> .meta {

		> .content {
			margin: 0 0 24px 0;

			p {
				margin: 0 0 16px 0;

			}

			a {
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}
		}

		> .contentsList,
		> .links {
			margin: 0;

			div {
				margin: 16px 0 0 0;
			}
		}

		> .contentsList {
			span {
				cursor: pointer;
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}
		}

		> .links {
			a {
				text-decoration: none;

				&:hover {
					span {
						text-decoration: underline;
					}
				}
			}
		}

		@include mediaAboveSmallScreen {
			@include clearfix;

			> .contentsList {
				float: left;
				width: calc(50% - 8px);
			}

			> .links {
				float: right;
				width: calc(50% - 8px);
			}
		}

		@include mediaSmallScreen {
			> .links {
				margin-top: 24px;
			}
		}
	}

	> .primaryImage {
		@include mediaAboveSmallScreen {
			margin: 40px 0 0 0;
		}

		@include mediaSmallScreen {
			margin: 24px 0 0 0;
		}
	}
}

.containerTitleAndMeta {
	@include clearfix;

	h1 {
		@include mediaAboveSmallScreen {
			float: left;
			max-width: percentage(550px / 2500px);
		}
	}

	.meta {
		p {
			margin: 10px 0 0 0;

			&:first-child {
				margin-top: 0;
			}
		}

		@include mediaAboveSmallScreen {
			float: right;
			max-width: percentage(1220px / 2500px);
		}
	}

	+ .primaryImage {
		margin-top: 20px;
		position: relative;
	}
}

.containerLastEdited {
	font-size: 12px;

	span {
		color: $colourGreyL2;
	}
}

.containerPageFeedback {
	> button,
	> span {
		@include inline-block;
		vertical-align: middle;
	}

	> button {
		margin-left: 5px;
	}

	> span {
		font-size: 13px;
	}
}

.containerGuidelinePagination {
	@include clearfix;
	margin-bottom: 24px;

	.buttonPaginationPrevious {
		float: left;
	}

	.buttonPaginationNext {
		float: right;
	}

	> a {
		font-size: 14px;
	}
}

.containerRelatedLinks {

	.links {
		@include clearfix;

		a {
			display: block;
			float: left;
			margin-bottom: 15px;
			margin-left: percentage(43px / 2780px);
			min-height: 28px;
			position: relative;
			text-decoration: none;
			width: percentage(426px / 2780px);

			&:nth-child(6n + 1) {
				margin-left: 0;
				clear: both;
			}

			&:hover {
				span {
					text-decoration: underline;
				}

				img {
					opacity: 0.7;
				}
			}

			> img {
				display: block;
			}

			> span {
				display: block;
				font-size: 13px;
				padding: 8px 0 0 0;
			}
		}

		@include mediaSmallScreen {
			a {
				margin-left: percentage(43px / 2780px);
				width: percentage(852px / 2780px);

				&:nth-child(3n + 1) {
					margin-left: 0;
					clear: both;
				}
			}

		}
	}
}

.containerPageFeedbackComment {
	label,
	p {
		color: $colourRedError;
		display: block;
		font-size: 13px;
		margin: 0;
		white-space: nowrap;
	}

	textarea {
		background: $colourWhite;
		border: 1px solid #333;
		display: block;
		font-family: $fontTelstraText;
		font-size: 13px;
		line-height: $fontTelstraTextLineHeight;
		margin: 5px 0 10px 0;
		max-height: 150px;
		min-height: 100px;
		outline: none;
		padding: 8px;
		resize: vertical;
		width: 300px;
	}

	button {
		background: $colourRedError;
		border: none;
		border-radius: 0;
		-webkit-border-radius: 0;
		color: $colourWhite;
		cursor: pointer;
		font-size: 12px;
		margin: 0;
		padding: 9px 8px 7px 8px;

		&:hover {
			background-color: $colourRedErrorHover;
		}
	}
}

.blockImageContainer {
	position: relative;

	> .blockImageMappedLinks {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 9;

		> a {
			position: absolute;
			text-decoration: none;
		}
	}
}

.imageTypeTabs {
	@include clearfix;
	margin-bottom: 16px;

	button {
		background-color: transparent;
		border: none;
		color: $colourGreyL1;
		cursor: pointer;
		float: left;
		font-size: 18px;
		font-weight: bold;
		padding: 8px 15px 16px 15px;
		@include transition(color 0.3s ease-in-out, border-bottom-color 0.3s ease-in-out);

		&:hover {
			border-bottom: 3px solid $colourTurquoiseL1;
			color: $colourTurquoiseL1;
		}

		&.active {
			border-bottom: 3px solid $colourTurquoiseL2;
			color: $colourTurquoiseL2;
		}
	}
}


.blue, .magenta {
	.notificationsBox {
		@include themedNotification($colourSky60, $colourSky80);
	}

	.whatsNew {
		@include themedWhatsNewPage($colourGreyL1, $colourSky60, $colourSky80);
	}
}

.green {
	.notificationsBox {
		@include themedNotification($colourGreenL2, $colourGreenL1);
	}

	.whatsNew {
		@include themedWhatsNewPage($colourGreyL1, $colourGreenL2, $colourGreenL1);
	}
}

.riModal {
	@extend .animated;
	@extend .fadeIn;

	position: fixed;
	top: 0;
	left: 0;

	background-color: rgba(0, 0, 0, 0.5);
	overflow-y: scroll;
	z-index: 25;

	@include size(100%);
	@include animation-duration(0.3s);

	&_box {
		background-color: $colourWhite;
		margin: 48px auto;
		padding: 40px;
		position: absolute;
		left: 50%;
		width: 750px;

		@include transform(translate(-50%));

		@include mediaSmallScreen {
			width: 80%;
		}
	}

	&_button {
		color: $colourGreyL2;
		background-color: transparent;
		border: none;
		margin: 0;
		padding: 0;
		font-size: 23px;
		position: absolute;
		top: 10px;
		right: 10px;
		cursor: pointer;
	}
}

.searchResultsTabs {

	&_buttonContainer {
		@include clearfix();

		margin-bottom: 32px;
	}

	&_button {
		background-color: transparent;
		border: none;
		color: $colourGreyL1;
		cursor: pointer;
		float: left;
		font-size: 18px;
		font-weight: bold;
		padding: 8px 15px 16px 15px;
		@include transition(color 0.3s ease-out, border-bottom-color 0.3s ease-out);

		&:not([disabled="disabled"]) {
			&:hover {
				border-bottom: 3px solid $colourOrangeL1;
				color: $colourOrangeL1;
			}
		}

		&[disabled="disabled"] {
			//color: $colourGreyL3; but accessible
			color: #939393;
			cursor: default;
		}

		&-active {
			border-bottom: 3px solid $colourOrangeL2;
			color: $colourOrangeL2;

			&:hover {
				border-bottom-color: $colourOrangeL2;
				color: $colourOrangeL2;
			}
		}
	}

	&_content {
		@include animation-delay(0.3s);
		@include clearfix();
	}

	&_item {
		&-elementContainer,
		&-element {
			@include span-columns(3);

			&.searchResultsTabs_item-lastOfRow {
				margin-right: 0;
			}
		}

		&-elementContainer,
		&-element {
			padding-bottom: 32px;

			h4 {
				color: $colourGreyL1;
				font-size: $h4DefaultSize;
				font-weight: bold;
				line-height: $fontTelstraDisplayLineHeightH4;
				margin: 0 0 16px 0;

				@include ellipsis(100%);
			}

			&_title {
				position: relative;
				margin: {
					top: 16px;
					bottom: 0;
				}

				&_downloadLink {
					color: $colourOrangeL2;
					cursor: pointer;
					font-size: $fontSmallSize;

					&:hover {
						color: $colourOrangeL1;

						:not([class*="td-icon"]) {
							text-decoration: underline;
						}
					}
				}

				&_text {
					color: $colourGreyL2;
					font-size: $fontSmallSize;
					float: right;
					text-decoration: none;

					@include ellipsis(50%);

					&:hover {
						color: $colourGreyL1;

						:not([class*="td-icon"]) {
							text-decoration: underline;
						}
					}
				}


			}

			&_imageContainer {
				display: block;
				height: 130px;
				position: relative;
				overflow: hidden;
				width: 100%;

				&-positive {
					background-color: $downloadsGrey;
				}

				&-negative {
					background-color: $colourGreyL2;
				}

				&-noPreview {
					color: $colourGreyL2;
					font-size: $fontSmallSize;
					font-style: italic;
					position: absolute;
					top: 50%;
					left: 50%;
					text-align: center;
					@include transform(translate(-50%, -50%));
				}

				&-image {
					$max: 100%;
					img {
						max-height: $max;
						max-width: $max;
					}
				}

				&-logoOrTemplate {
					img {
						max-height: 90%;
						max-width: 80%;
					}
				}

				img {
					position: absolute;
					top: 50%;
					left: 50%;
					@include transform(translate(-50%, -50%));
				}
			}
		}

		&-page {
			border-bottom: 1px solid $colourGreyL3;
			padding: 24px 0;

			&:last-child {
				border-bottom: none;
			}

			h4 {
				font-size: $h4DefaultSize;
				margin: {
					top: 0;
					bottom: 16px;
				}
			}

			a {
				color: $colourOrangeL2;
				text-decoration: none;

				&:hover {
					color: $colourOrangeL1;

					:not([class*="td-icon"]) {
						text-decoration: underline;
					}
				}
			}

			p {
				margin: {
					top: 0;
					bottom: 0;
				}
				font-size: $fontDefaultSize;

				&.noDesc {
					color: $colourGreyL2;
					font-size: $fontSmallSize;
					font-style: italic;
				}
			}
		}
	}

	.pagination {
		li {
			> a {
				color: $colourOrangeL2;
			}

			&.active,
			&:hover {
				> a {
					background: $colourOrangeL2;
					color: white;
				}
			}
		}
	}
}

.ui-datepicker {
	background-color: $colourWhite;
	border-radius: 4px;
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
	margin-top: 5px;

	.ui-datepicker-header {
		.ui-datepicker-next,
		.ui-datepicker-prev {
			@extend .fa;
			cursor: pointer;
			font-size: 28px;
			color: $colourTurquoiseL2;
			@include size(20px 30px);
		}
	}

	.ui-state-disabled {
		opacity: 0.3;
	}

	td span,
	td a {
		text-align: center;
	}

	&-next {
		@extend .fa-angle-right;
		text-align: right;
	}

	&-prev {
		@extend .fa-angle-left;
	}

	.ui-state-default {
		color: $colourTurquoiseL2;
	}

	.ui-state-active {
		font-weight: bold;
		color: $colourWhite;
		background-color: $colourTurquoiseL2;
	}
}

@mixin datepicker-colour($colour) {
	.ui-datepicker-header {
		.ui-datepicker-next,
		.ui-datepicker-prev {
			color: $colour;
		}
	}

	.ui-state-default {
		color: $colour;
	}

	.ui-state-active {
		color: $colourWhite;
		background-color: $colour;
	}
}

.turquoise.ui-datepicker {
	@include datepicker-colour($colourTurquoiseL2);
}

.orange.ui-datepicker {
	@include datepicker-colour($colourOrangeL2);
}

.pageContactUs{

	.formContactUs {
		width: 100%;
		max-width: 600px;

		button {
			width: 50%;
		}
	}

	.field {
		a {
			color: $colourEarth60;
		}
	}
}


