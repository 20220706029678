.badgeGeneratorLogoBlue {
	background-image: url($imageGeneratorDir + 'logos_2019/onWhite/Primary-Blue-icon.png')!important;
}
.badgeGeneratorLogoGreen {
	background-image: url($imageGeneratorDir + 'logos_2019/onWhite/Primary-Green-icon.png')!important;
}
.badgeGeneratorLogoMagenta {
	background-image: url($imageGeneratorDir + 'logos_2019/onWhite/Primary-Magenta-icon.png')!important;
}
.badgeGeneratorLogoSky80 {
	background-image: url($imageGeneratorDir + 'logos_2023/CS_Sky_80.png')!important;
}
.badgeGeneratorLogoSky60 {
	background-image: url($imageGeneratorDir + 'logos_2023/CS_Sky_60.png')!important;
}
.badgeGeneratorLogoEarth60 {
	background-image: url($imageGeneratorDir + 'logos_2023/CS_Earth_60.png')!important;
}
.badgeGeneratorLogoSand40 {
	background-image: url($imageGeneratorDir + 'logos_2023/CS_Sand_40.png')!important;
}
.badgeGenerator {
	padding-bottom: 0;
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Chrome/Safari/Opera */
	-khtml-user-select: none; /* Konqueror */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none;

	//input[type="checkbox"] {
	//	font-size: xx-large;
	//	vertical-align: text-bottom;
	//	margin-top: 2px;
	//	margin-right: 2px;
	//}
	p {
		margin: 0 0 16px 0;

		&:not(:last-of-type) {
			margin-bottom: 0;
		}
	}

	.partnerLevel {
		display: inline-block;
		width: 250px;
		height: 45px;
		line-height: 46px;
		border: none;
		padding: 0;


		span {
			margin: auto;
			color: $colourWhite;
			font-size: $h3DefaultSize;
		}

		&.level {
			background-color: $colourEarth40;
		}

		&.domain {
			background-color: $colourSand20;
		}
	}

	label {
		&.unavailable {
			color: $colourGreyL5;
		}

		&.available {
			color: $colourGreyL1;
		}
	}

	.field {
		@include clearfix;
		min-width: 720px;
		margin-bottom: 24px;

		&.last {
			margin-top: 32px;
		}

		a {
			color: $colourEarth60;
		}

	}

	.imageContainer {
		clear: both;

		p {
			font-weight: bold;
			margin: 10px 0 2px 0;
		}
	}

	#imageProxyContainerInline,
	#imageProxyContainerStacked,
	#imageProxyContainerSlim {
		max-width: 700px;
		margin-left: 20px;

		img {
			//width: 100%;
			//max-height: 265px;
			//width: 100%;
			//border: 1px solid $colourGreyL1;
		}

		&.default {
			background-color: $colourSand20;
			width: 700px;
		}
		&.withImg {
			background-color: transparent;

			.imageBackground {
				background-color: $colourSand20;
				padding: 10px;
			}
		}
		&.no-indent {
			margin-left: 0;
		}
	}

	#imageProxyContainerStacked,
	#imageProxyContainerSlim {
		min-height: 50px;
		margin-bottom: 10px;
	}

	#imageProxyContainerInline {
		min-height: 50px;
		max-width: 700px;
		margin-left: 20px;
		margin-bottom: 20px;
	}

	#imageProxy {
		max-width: none;
		pointer-events: none;
		//position: absolute;
		width: 100%;
	}

	.colour {
		@include clearfix;

		> div:not(:last-child) {
			margin-right: 16px;
		}

		.colourButton {
			background: no-repeat center;
			background-size: contain;
			cursor: pointer;
			display: inline-block;
			height: 40px;
			width: 40px;

			&.disabled {
				pointer-events: none;
			}

			&:hover {
				outline: 3px solid $colourGrey40;
				outline-offset: -3px;
				border-radius: 10px;
			}
		}
	}

	.active {
		outline: 3px solid $colourGrey40;
		outline-offset: -3px;
		border-radius: 10px;
	}

	.downloadButton {
		background-color: $colourEarth60;
		border: none;
		color: white;
		cursor: pointer;
		font-size: $fontDefaultSize;
		font-weight: bold;
		line-height: $fontTelstraTextLineHeight;
		padding: 15px 20px;
		text-align: left;
		width: 280px;
		border-radius: 10px;

		i {
			float: right;
		}
		span {
			padding-left: 10px;
		}
		&:hover {
			background-color: $colourEarth80;
		}

		&.clicked {
			background-color: $colourEarth80;
			cursor: default;
			i {
				font-size: 24px;
				float: left;
				margin-left: 50px;
			}

			span {
				padding-left: 10px;
			}
		}
	}

	.infoButton {
		border: none;
		color: black;
		cursor: pointer;
		margin: 0;
		margin-left: 5px;
		outline: none;
		padding: 0;
		position: relative;
		vertical-align: middle;
		width: 20px;
		line-height: 20px;

		> i {
			border: 1px solid currentColor;
			border-radius: 50%;
			color: inherit;
			display: inline-block;
			font-size: 14px;
			font-style: normal;
			font-family: $fontTelstraText;
			padding: 0;
			position: relative;
			height: 20px;
			width: 20px;
			margin: 0;
		}

		&:active,
		&:focus,
		&:hover {
			color: $colourGreyL1;
		}

		&:active,
		&:focus {
			outline: none;
		}
	}

	.imageInfo {
		background: $colourWhite;
		box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
		font-size: $fontDefaultSize;
		outline: none;
		padding: 16px;
		position: absolute;
		left: 12px;
		top: 30px;
		text-align: left;
		width: 200px;
		z-index: 2;
	}

	.checkContainer {
		display: block;
		position: relative;
		padding-left: 33px;
		margin-bottom: 12px;
		cursor: pointer;

		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;

		&.level {
			font-size: 20px;
		};

		&.domain {
			font-size: 15px;
		}

		&.award {
			font-size: 16px;
			//padding-left: 33px;
			line-height: 24px;
			margin: 0;
			padding: 5px 33px 5px 0;
		}

		&.design {
			font-weight: bold;
			padding-left: 20px;
			margin-bottom: 0;
		}

		&.format {
			padding-left: 20px;
		}
	}

	/* Hide the browser's default checkbox */
	.checkContainer input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	/* Create a custom checkbox */
	.checkmark {
		position: absolute;
		border-radius: 3px;
		top: 15px;
		left: 10px;
		height: 14px;
		width: 14px;
		border: 1px solid $colourEarth80;
		background-color: $colourSand20;

		&.award {
			top: 9px;
			left: 9px;
			height: 13px;
			width: 13px;
		}

		&.design {
			left: 0;
			top: 4px;
		}
	}

	/* On mouse-over, add a grey background color */
	.checkContainer:hover input ~ .checkmark {
		//background-color: #ccc;
	}

	/* When the checkbox is checked, add a blue background */
	.checkContainer input:checked ~ .checkmark {
		background-color: $colourEarth80;
	}

	/* Create the checkmark/indicator (hidden when not checked) */
	.checkmark:after {
		content: "";
		position: absolute;
		display: none;
	}

	/* Show the checkmark when checked */
	.checkContainer input:checked ~ .checkmark:after {
		display: block;
	}

	/* Style the checkmark/indicator */
	.checkContainer .checkmark:after {
		left: 4px;
		top: 1px;
		width: 3px;
		height: 7px;
		border: solid white;
		border-width: 0 2px 2px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);

		&.award {
			left: 3px;
			top: 0;
		}
	}

	.checkContainer .checkmark.award:after {
		left: 3px;
		top: 0;
		width: 3px;
		height: 7px;
		border: solid white;
		border-width: 0 2px 2px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}

	.or {
		font-weight: bold;
		margin: 16px 0;
	}

	.awardSelectContainer {
		border:1px solid #000;

		max-height:202px;
		width:700px;

		ul{
			list-style:none;
			max-height:200px;
			margin:0;
			overflow:auto;
			padding:0;
			text-indent:33px;
		}
		li{
			line-height:24px;
		}
	}

	.riModal_box {
		width: 500px;
		top: 30%;
		margin-top: 0;

		.alertButton {
			border: none;
			margin: 0;
			cursor: pointer;
			padding: 12px 14px;
			font-size: 16px;
			background-color: $colourEarth60;
			color: #fff;

		}
	}

	.btnPreview {
		background: $colourEarth60;
		font-weight: bold;
		border: none;
		padding: 10px;
		color: white;
		width: 190px;
		margin-bottom: 20px;
		border-radius: 10px;

		&:hover{
			background: $colourEarth80;
			cursor: pointer;
		}
		span {
			float: left;
			line-height: 30px;
			font-size: 16px;
			padding-left: 10px;
		}
		i {
			font-size: 20px;
			color: white;
			float: left;
			line-height: 30px;
			margin-left: 10px;
		}

		&.clicked {
			background: $colourEarth80;
			cursor: default;

			i {
				margin-left: 5px;
			}

			span {
				padding-left: 6.5px;
			}
		}

	 }
}
