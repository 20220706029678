.headerMain {
	position: fixed;
	top: 0;
	width: 100vw;

	background: #fff;
	overflow-anchor: none;
	//visibility: hidden;
	z-index: 15;

	@include clearfix();

	&-wrapper {
		position: relative;
		// padding: 20px 30px;
		border-bottom: 1px solid $colourGreyL4;
		z-index: 10;

		@include mediaAboveSmallScreen {
			// padding: 20px 20px;
		}

		@include outer-container();
		max-width:100%;
	}

	&_container {
		float: left;
	}

	.fa-bars {
		float: left;
		font-size: 30px;
		width: 30px;
		padding: 5px 0 5px 5px;
		margin: 19px 10px;
		cursor: pointer;
		overflow:hidden;
		transition: all 0.6s;
		.showMenu &{
			width: 0;
			padding-left: 0;
			padding-right: 0;
			margin-right: 0;
		}
		//@include mediaAboveSmallScreen {
			display: none;
		//}
	}

	.fa-times {
		float: right;
		font-size: 30px;
		width: 0;
		padding-top: 5px;
		padding-bottom: 5px;
		padding-left: 0;
		padding-right: 0;
		margin: 19px 10px;
		cursor: pointer;
		overflow:hidden;
		transition: all 0.6s;
		.showMenu &{
			width: 30px;
			padding: 5px;
			margin-left: 15px;
		}
		@include mediaAboveSmallScreen {
			display: none;
		}
	}

	.toolBox {
		float: right;
		@include clearfix();
		@include mediaSmallScreen{
			display: none;
		}

		&_item {
			float: left;


			// search box in desktop header
			&-search {
				@include mediaSmallScreen{
					display: none !important;
				}

				border-bottom: 1px solid $colourGreyL3;
				color: $colourGreyL2;
				padding-right: 40px;
				position: relative;
				margin: 21px 10px 20px 10px;
				z-index: 20;

				input {
					color: $colourGreyL1;
					border: none;
					font: {
						family: $fontTelstraText;
						size: 16px;
					}
					line-height: 1.15em;
					outline: none;
					padding: 12px 0 12px 12px;
					width: 220px;

					&::-ms-clear { /*IE10+ remove the X to clear*/
						width : 0;
						height: 0;
					}
				}

				button {
					background: none;
					color: inherit;
					position: absolute;
					right: 7px;
					top: 7px;
					border: none;
					padding: 0;
					cursor: pointer;

					&:hover {
						color: $colourGreyL1;
					}

				}

				input:not(:placeholder-shown) ~ .floatingLabel,
				input:focus ~ .floatingLabel,
				input.notEmpty ~ .floatingLabel  {
					color: $colourGreyL1;
					font-size: 14px;
					font-style: normal;
					top: -11px;
				}

				.floatingLabel {
					color: $colourGreyL3;
					font-size: 16px;
					font-style: italic;
					pointer-events: none;
					position: absolute;
					left: 12px;
					top: 10px;

					@include ellipsis(calc(100% - 40px));
					@include transition(all 0.2s ease);
				}

				.searchIcon {
					opacity: 1;
				}

				.clearIcon {
					opacity: 0;
					@include transition(opacity 0.25s ease);
				}

				&.focusWithin,
				&.showClearIcon {
					.searchIcon {
						opacity: 0;
						@include transition(opacity 0.25s ease);
					}

					.clearIcon {
						opacity: 1;
					}
				}

				@include gradientUnderline();

				&::after {
					opacity: 0;
					@include transition(opacity 0.3s ease-in-out);

				}

				&.focusWithin,
				&:hover {
					color: $colourGreyL1;

					&::after {
						opacity: 1;
					}
				}
			}

			&-home {
				padding-top: 8px;
			}

			&-notificationsBox {
				$fontColour: $colourGreyL1;
				$width: 300px;
				$maxHeight: 522px;
				$spacing: 15px;
				padding: 7px 10px 19px 10px;
				margin-top: 12px;
				position: relative;
				z-index: 9;
				display: inline-block;

				&-blue, &-magenta, &-sky {
					@include themedNotificationBox($colourSky60);
				}

				&-green {
					@include themedNotificationBox($colourGreenL2);
				}

			}

			&-logOut {
				padding: 30px 15px;

				a {
					color: $colourGreyL2;
					font: {
						family: $fontTelstraText;
						size: 16px;
					}
					line-height: 24px;
					text-decoration: none;

					@include transition(opacity 0.3s);

					&:hover {
						color: $colourGreyL1;
						text-decoration: underline;
					}
				}
			}

			&-needHelp {
				line-height: 20px;
				padding: 30px;

				a {
					display: block;
					text-decoration: none;

					&:hover {
						.text {
							text-decoration: underline;
						}
					}
				}

				&.blue, &.magenta, &.sky {
					@include themedNeedHelp($colourSky60, $colourSky80);
				}
				&.earth {
					@include themedNeedHelp($colourEarth60, $colourEarth80);
				}

				&.green {
					@include themedNeedHelp($colourGreenL2, $colourGreenL1);
				}

				.needHelpLink {
					span {
						&.text {
							margin-left: 4px;
						}
					}
				}

			}
		}
	}

	&.activeSearch,
	.layout-searchPage & {
		.containerLogos {
			.orange {
				opacity: 1;
				z-index: 99;
			}
		}
	}

	&.noUser {
		.containerLogos {
			@include inline-block();

			&_logo-magenta {
				opacity: 1;
				z-index: 99;
			}

			@include mediaAboveSmallScreen {
				padding-right: 120px;
			}
		}
	}
}