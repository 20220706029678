// Colours
$colourWhite: #FFFFFF;
$colourBlack: #000000;

$colourRedError: #D0021B;
$colourRedErrorHover: #AC0442;
$colourRedDanger: #FDF7F8;

$colourGreenL1: #074F30;
$colourGreenL2: #008A00;
$colourGreenL3: #00ac00;
$colourGreenL4: #B2E6B2;
$pct50LightGreen: #b2e6b27a;

$colourTurquoiseL1: #025450;
$colourTurquoiseL2: #008381;
$colourTurquoiseL3: #2AA4A2;
$colourTurquoiseL4: #BFE4E3;

$colourBlueL1: #001E82;
$colourBlueL2: #0064D2;
$colourBlueL3: #0099F8;
$colourBlueL4: #B2E0FD;

$colourPurpleL1: #35237F;
$colourPurpleL2: #5E50BF;
$colourPurpleL3: #8A92C5;
$colourPurpleL4: #DCDEEE;

$colourMagentaL1: #570066;
$colourMagentaL2: #A0148C;
$colourMagentaL3: #FF2896;
$colourMagentaL4: #FFBEDF;
$pct25LightMagenta: #FFEFF7;
$pct50LightMagenta: #FFDEEF;

$colourOrangeL1: #9E043E;
$colourOrangeL2: #D93923;
$colourOrangeL3: #F05A00;
$colourOrangeL4: #facdb2;

$colourGreyL1: #414141;
$colourGreyL2: #787878;
$colourGreyL3: #aaaaaa;
$colourGreyL4: #d2d2d2;
$colourGreyL5: #e6e6e6;

$downloadsGrey: #f3f3f3;

$colourDefaultFont: $colourGreyL1;

$colourSky100: #131A35;
$colourSky80: #1A2E61;
$colourSky60: #0D54FF;
$colourSky40: #6CCBFE;
$colourSky20: #D8F0FE;

$colourSand60: #D4CDC3;
$colourSand40: #F5EDE2;
$colourSand20: #FEF9EF;

$colourEarth80: #F44E1A;
$colourEarth60: #F96449;
$colourEarth40: #FFAE87;
$colourEarth20: #FFDDD1;

$colourGrey100: #050505;
$colourGrey80: #505050;
$colourGrey60: #828282;
$colourGrey40: #A0A0A0;
$colourGrey30: #C8C8C8;
$colourGrey20: #E3E3E3;
$colourGreyFooter: #F4F4F4;


// Font sizes
$h1DefaultSize:		48px;
$h2DefaultSize:		36px;
$h3DefaultSize:		24px;
$h4DefaultSize:		18px;
$h5DefaultSize:		14px;
$pDefaultSize:		18px;
$fontDefaultSize:	18px;
$fontSmallSize:		14px;
