@mixin td-icon-override() {
	line-height: 22px;
	pointer-events: none;
	position: absolute;
	right: 20px;
	top: 0;
}

@mixin navigation-colours($colour:$colourGreyL2) {
	.active > .itemLabel > .itemLabelInner {
		border-bottom: 2px solid $colour;
	}

	> ul > li >.td-icon {
		@include td-icon-override;
		padding: 13px 0;
		color: $colour;
	}
}

.navigation {
	//hide ugly scrollbar on ie11
	-ms-overflow-style: none;
	background-color: $colourSand20;

	min-height: 0;
	//position: absolute;
	//left: 0;
	transition: all 0.8s;
	width: 290px;
	z-index: 10;

	@include mediaSmallScreen {
		width: 100vw;
	}
	padding: 20px 0;

	ul {
		padding: 0;
		list-style: none;
		width: 100%;
	}

	li {
		width: 100%;
		position: relative;

		> .itemLabel:hover,
		> .itemLabel:focus {
			background: $colourSand60;
		}
	}

	a {
		text-decoration: none;
		color: $colourGreyL1;
		padding-bottom: 2px;
		&:hover, &:active, &:visited, &:focus{
			text-decoration: none;
			color: $colourGreyL1;
			padding-bottom: 2px;
		}
	}

	.itemLabelInner {
		padding-bottom: 4px;
	}

	.icon-ui-down-arrow-thick {
		display: block;
	}

	.icon-ui-up-arrow-thick {
		display: none;
	}

	ul.open,
	ul.openToggled {
		& ~ .icon-ui-down-arrow-thick {
			display: none;
		}

		& ~ .icon-ui-up-arrow-thick {
			display: block;
		}
	}

	> ul {
		margin-bottom: 10px;
		clear: both;

		> li {
			cursor: pointer;
			font-size: 20px;
			font-weight: 400;
			line-height: 29px;

			.itemLabel {
				display: block;
				padding: 13px 40px 13px 20px;
				margin: 0;
			}

			> ul {
				transition: opacity 0.6s, max-height 0.6s;
				opacity: 0;
				max-height: 0;
				overflow: hidden;

				&.open,
				&.openToggled {
					opacity: 1;
					max-height: 2000px;
					transition: opacity 1.2s, max-height 1.2s;

				}

				> li {
					font-size: $fontDefaultSize;
					font-weight: normal;
					line-height: 30px;

					.itemLabel {
						display: block;
						padding: 10px 40px 10px 44px;
						margin: 0;
					}

					&.open,
					&.openToggled {
						font-weight: bold;

						li {
							font-weight: normal;
						}
					}

					.td-icon {
						@include td-icon-override;
						padding: 12px 0;
						color: $colourGreyL1;
					}

					> ul {
						transition: opacity 0.6s, max-height 0.6s;
						opacity: 0;
						max-height:0;
						overflow: hidden;

						&.open,
						&.openToggled {
							opacity: 1;
							max-height: 2000px;
							transition: opacity 1.2s, max-height 1.2s;
						}

						> li {
							font-size: $fontDefaultSize;
							line-height: 30px;

							.itemLabel {
								display: block;
								padding: 10px 40px 10px 60px;
								margin: 0;
							}

							&.open,
							&.openToggled {
								font-weight: bold;

								li {
									font-weight: normal
								}
							}

							> ul {
								transition: opacity 0.6s, max-height 0.6s;
								opacity: 0;
								max-height:0;
								overflow: hidden;

								&.open,
								&.openToggled {
									opacity: 1;
									max-height: 2000px;
									transition: opacity 1.2s, max-height 1.2s;
								}

								> li {
									font-size: 16px;
									line-height: 30px;

									.itemLabel {
										display: block;
										padding: 10px 40px 10px 76px;
										margin: 0;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	&.grey {
		@include navigation-colours($colourGreyL1);
	}

	&.green {
		@include navigation-colours($colourGreenL2);
	}

	.toolBox {
		@include clearfix();
		margin-left: 8px;
		float: right;

		.showMenu & {
			display: none;
		}

		&_item {
			margin-right: 10px;
			margin-bottom: 20px;
			float: left;

			@include mediaAboveSmallScreen {
				display: none;
			}

			&:last-child {
				margin-right: 0;
			}


			// search box in mobile menu
			&-search {
				border-bottom: 1px solid $colourGreyL3;
				position: relative;
				padding-top: 3px;
				margin: 0 20px 20px 20px;
				z-index: 20;
				width: calc(100% - 40px);

				input {
					background: none;
					border: none;
					font: {
						family: $fontTelstraText;
						size: 16px;
					}
					line-height: $fontTelstraTextLineHeight;
					outline: none;
					padding: 7px 30px 7px 5px;
					width: 100%;

					@include placeholder {
						color: $colourGreyL3;
						font-style: italic;
					}

					&::-ms-clear { /*IE10+ remove the X to clear*/
						width : 0;
						height: 0;
					}
				}

				button {
					color: inherit;
					position: absolute;
					top: 7px;
					right: 7px;
					border: none;
					cursor: pointer;
					padding: 0;
					background: none;

					&:hover {
						color: $colourGreyL1;
					}
				}

				.searchIcon {
					opacity: 1;
				}

				.clearIcon {
					opacity: 0;
					@include transition(opacity 0.25s ease);
				}

				&:focus-within,
				&.showClearIcon {
					.searchIcon {
						opacity: 0;
						@include transition(opacity 0.25s ease);
					}

					.clearIcon {
						opacity: 1;
					}
				}

				@include gradientUnderline();

				&::after {
					opacity: 0;
					@include transition(opacity 0.3s ease-in-out);

				}

				&:focus-within,
				&:hover {
					color: $colourGreyL1;

					&::after {
						opacity: 1;
					}
				}
			}
		}
	}
}