.SumoSelect p {
    margin: 0;
}

.SumoSelect {
	width: 100%;
}

.SelectBox {
    padding: 8px;
}

.sumoStopScroll {
    overflow: hidden;
}


/* Filtering style */

.SumoSelect .hidden {
    display: none;
}

.SumoSelect .search-txt {
    display: none;
    outline: none;
}

.SumoSelect .no-match {
    display: none;
    padding: 6px;
}

.SumoSelect.open .search-txt {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 5px 8px;
    border: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.SumoSelect.open>.search>span,
.SumoSelect.open>.search>label {
    visibility: hidden;
}


/*this is applied on that hidden select. DO NOT USE display:none; or visiblity:hidden; and
Do not override any of these properties. */

.SelectClass,
.SumoUnder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    border: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
}

.SelectClass {
    z-index: 1;
}

.SumoSelect>.optWrapper>.options li.opt label,
.SumoSelect>.CaptionCont,
.SumoSelect .select-all>label {
    user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
}

.SumoSelect {
    display: inline-block;
    position: relative;
    outline: none;
}

.SumoSelect:focus>.CaptionCont,
.SumoSelect:hover>.CaptionCont,
.SumoSelect.open>.CaptionCont {
    box-shadow: 0 0 2px #7799D0;
    border-color: #7799D0;
}

.SumoSelect>.CaptionCont {
    position: relative;
    border-bottom: 1px solid $colourGreyL3;
	min-height: 14px;
	background-color: $colourWhite;
    margin: 0;
}

.SumoSelect>.CaptionCont>span {
    display: block;
    padding-right: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: default;
}


/*placeholder style*/

.SumoSelect>.CaptionCont>span.placeholder {
    color: $colourGreyL3;
    font-style: italic;
}

.SumoSelect>.CaptionCont>label {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 30px;
}

.SumoSelect>.CaptionCont>label>i {
    //background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAYAAABy6+R8AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH3wMdBhAJ/fwnjwAAAGFJREFUKM9jYBh+gBFKuzEwMKQwMDB8xaOWlYGB4T4DA0MrsuapDAwM//HgNwwMDDbYTJuGQ8MHBgYGJ1xOYGNgYJiBpuEpAwODHSF/siDZ+ISBgcGClEDqZ2Bg8B6CkQsAPRga0cpRtDEAAAAASUVORK5CYII=');
    //background-position: center center;
	//background-repeat: no-repeat;
	width: 16px;
	height: 16px;
	display: block;
	position: absolute;
	top: -5px;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	font-style: normal;

	&:before {
		color: $colourGreyL1;
		content: "\e6e3";
		font-family: 'td-icons';
	}
}

.SumoSelect>.optWrapper {
    display: none;
    z-index: 1000;
    top: 26px;
    width: auto;
    min-width: 100%;
    max-width: 400px;
    position: absolute;
    left: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background: $colourWhite;
    border: 1px solid $colourGreyL3;
    box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.11);
    overflow: hidden;
}

.SumoSelect.open>.optWrapper {
    top: 35px;
    display: block;
}

.SumoSelect.open>.optWrapper.up {
    top: auto;
    bottom: 100%;
    margin-bottom: 5px;
}

.SumoSelect>.optWrapper ul {
    list-style: none;
    display: block;
    padding: 0;
    margin: 0;
    overflow: auto;
}

.SumoSelect>.optWrapper>.options {
    position: relative;
    /*Set the height of pop up here (only for desktop mode)*/
    max-height: 350px;
    /*height*/
}

.SumoSelect>.optWrapper.okCancelInMulti>.options {
    border-radius: 0;
}

.SumoSelect>.optWrapper.selall>.options {
    border-radius: 0;
}

.SumoSelect>.optWrapper.selall.okCancelInMulti>.options {
    border-radius: 0;
}

.SumoSelect>.optWrapper>.options li.group.disabled>label {
    opacity: 0.5;
}

.SumoSelect>.optWrapper>.options li ul li.opt {
    padding-left: 22px;
}

.SumoSelect>.optWrapper.multiple>.options li ul li.opt {
    padding-left: 50px;
}

.SumoSelect>.optWrapper.isFloating>.options {
    max-height: 100%;
    box-shadow: 0 0 100px $colourGreyL2;
}

.SumoSelect>.optWrapper>.options li.opt {
	padding: 8px;
    position: relative;
    border-bottom: 1px solid $colourGreyL4;
}

.SumoSelect>.optWrapper>.options>li.opt:first-child {
    border-radius: 0;
}

.SumoSelect>.optWrapper.selall>.options>li.opt:first-child {
    border-radius: 0;
}

.SumoSelect>.optWrapper>.options>li.opt:last-child {
    border-radius: 0;
    border-bottom: none;
}

.SumoSelect>.optWrapper.okCancelInMulti>.options>li.opt:last-child {
    border-radius: 0;
}

.SumoSelect>.optWrapper>.options li.opt:hover {
    background-color: $colourGreyL4;
}

.SumoSelect>.optWrapper>.options li.opt.sel,
.SumoSelect .select-all.sel {
    background-color: red;
}

.SumoSelect>.optWrapper>.options li label {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    cursor: pointer;
	position: relative;
	top: 1px;
}

.SumoSelect>.optWrapper>.options li span {
    display: none;
}

.SumoSelect>.optWrapper>.options li.group>label {
    cursor: default;
    padding: 8px 6px;
    font-weight: bold;
}


/*Floating styles*/

.SumoSelect>.optWrapper.isFloating {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 90%;
    bottom: 0;
    margin: auto;
    max-height: 90%;
}


/*disabled state*/

.SumoSelect>.optWrapper>.options li.opt.disabled {
    background-color: inherit;
    pointer-events: none;
}

.SumoSelect>.optWrapper>.options li.opt.disabled * {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    /* IE 5-7 */
    filter: alpha(opacity=50);
    /* Netscape */
    -moz-opacity: 0.5;
    /* Safari 1.x */
    -khtml-opacity: 0.5;
    /* Good browsers */
    opacity: 0.5;
}



/*styling for multiple select*/

.SumoSelect>.optWrapper.multiple>.options li.opt {
    padding-left: 35px;
    cursor: pointer;
}

.SumoSelect>.optWrapper.multiple>.options li.opt span,
.SumoSelect .select-all>span {
    position: absolute;
    display: block;
    width: 30px;
    top: 0;
    bottom: 0;
    margin-left: -35px;
}

.SumoSelect>.optWrapper.multiple>.options li.opt span i,
.SumoSelect .select-all>span i {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 14px;
    height: 14px;
    border: 1px solid $colourGreyL3;
    border-radius: 0;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.15);
    background-color: $colourWhite;
}

.SumoSelect>.optWrapper>.MultiControls {
    display: none;
    border-top: 1px solid $colourGreyL4;
    background-color: $colourWhite;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.13);
    border-radius: 0;
}

.SumoSelect>.optWrapper.multiple.isFloating>.MultiControls {
    display: block;
    margin-top: 5px;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.SumoSelect>.optWrapper.multiple.okCancelInMulti>.MultiControls {
    display: block;
}

.SumoSelect>.optWrapper.multiple.okCancelInMulti>.MultiControls>p {
    padding: 6px;
}

.SumoSelect>.optWrapper.multiple.okCancelInMulti>.MultiControls>p:focus {
    box-shadow: 0 0 2px #a1c0e4;
    border-color: #a1c0e4;
    outline: none;
    background-color: #a1c0e4;
}

.SumoSelect>.optWrapper.multiple>.MultiControls>p {
    display: inline-block;
    cursor: pointer;
    padding: 12px;
    width: 50%;
    box-sizing: border-box;
    text-align: center;
}

.SumoSelect>.optWrapper.multiple>.MultiControls>p:hover {
    background-color: $colourGreyL5;
}

.SumoSelect>.optWrapper.multiple>.MultiControls>p.btnOk {
    border-right: 1px solid $colourGreyL5;
    border-radius: 0;
}

.SumoSelect>.optWrapper.multiple>.MultiControls>p.btnCancel {
    border-radius: 0;
}


/*styling for select on popup mode*/

.SumoSelect>.optWrapper.isFloating>.options li.opt {
    padding: 12px 6px;
}


/*styling for only multiple select on popup mode*/

.SumoSelect>.optWrapper.multiple.isFloating>.options li.opt {
    padding-left: 35px;
}

.SumoSelect>.optWrapper.multiple.isFloating {
    padding-bottom: 43px;
}

.SumoSelect>.optWrapper.multiple>.options li.opt.selected span i,
.SumoSelect .select-all.selected>span i,
.SumoSelect .select-all.partial>span i {
    background-color: $colourTurquoiseL2;
    box-shadow: none;
    border-color: transparent;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAGCAYAAAD+Bd/7AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABx0RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNXG14zYAAABMSURBVAiZfc0xDkAAFIPhd2Kr1WRjcAExuIgzGUTIZ/AkImjSofnbNBAfHvzAHjOKNzhiQ42IDFXCDivaaxAJd0xYshT3QqBxqnxeHvhunpu23xnmAAAAAElFTkSuQmCC');
    background-repeat: no-repeat;
    background-position: center center;
}


/*disabled state*/

.SumoSelect.disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.SumoSelect.disabled>.CaptionCont {
    border-color: $colourGreyL3;
    box-shadow: none;
}


/**Select all button**/

.SumoSelect .select-all {
    position: relative;
    border-bottom: 1px solid $colourGreyL4;
    background-color: $colourWhite;
    padding: 8px 0 8px 35px;
    cursor: pointer;

	&:hover {
		background-color: $colourGreyL4;
	}

	>label {
		position: relative;
		top: 1px;
	}
}

.SumoSelect .select-all>label,
.SumoSelect .select-all>span i {
    cursor: pointer;
}

.SumoSelect .select-all.partial>span i {
    background-color: $colourGreyL2;
}



/*styling for optgroups*/

.SumoSelect>.optWrapper>.options li.optGroup {
    padding-left: 5px;
    text-decoration: underline;
}